import { V1 } from "../generated/api/V1"
import { V2 } from "../generated/api/V2"

function createApi<T>(apiClass: new (options: any) => T): T {
  return new apiClass({
    customFetch: getCustomFetch(),
  })
}

// export const api = new V1({
//   customFetch: getCustomFetch({
//     // async afterRequest(sharedData: SharedData, response?: Response | null) {
//     //   const responseBody = await response?.json()
//     //   if (responseBody?.errorCode == 401) {
//     //     // 所有接口返回 401 都要重新登录
//     //     window.location.reload()
//     //   }
//     // },
//   }),
// })

// api.baseUrl = "" // fix host

function getCustomFetch(options?: GetCustomFetchOptions) {
  const { beforeRequest, afterRequest } = options || {}
  const originalFetch = window.fetch
  if (typeof originalFetch === "function") {
    return function (input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
      const request = new Request(input, init)
      const { url, method } = request
      const sharedData: SharedData = { url, method }
      const newRequest = beforeRequest?.(sharedData, request)
      const res: Promise<Response> = originalFetch.call(window, newRequest || request)
      res
        .then((response) => {
          afterRequest?.(sharedData, response.clone())
          return response
        })
        .catch((reason) => {
          afterRequest?.(sharedData, null, reason)
          throw reason
        })
      return res
    }
  }
}

export const api = createApi(V1)
export const apiV2 = createApi(V2)

api.baseUrl = "" // fix host
apiV2.baseUrl = "" // fix host

interface GetCustomFetchOptions {
  beforeRequest?(sharedData: SharedData, request: Request): void | Request
  afterRequest?(
    sharedData: SharedData,
    response?: Response | null,
    reason?: any
  ): void | Promise<void>
}
interface SharedData {}
