/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  RepoOrder,
  ResponseResponseWrapper,
  V1AbuseReportSubmitReq,
  V1ApplyInviteCodeRequest,
  V1BatchDeleteCollectionRequest,
  V1BatchDeleteCollectionResponse,
  V1BatchLikeWorkRequest,
  V1BatchSetIsPrivateRequest,
  V1BatchSetWorksIsPrivateRequest,
  V1BatchUnLikeWorkRequest,
  V1BindCategoryRequest,
  V1Category,
  V1CheckGuestCreatedTaskResponse,
  V1CheckLoginResponse,
  V1CoinRedeemDesc,
  V1CreateCollectionRequest,
  V1CreateCollectionResponse,
  V1CreateImagePromptForGuestRequest,
  V1CreateImagePromptRequest,
  V1CreateImagePromptResponse,
  V1CreateNotificationReq,
  V1CreateOrderResp,
  V1CreateTaskForGuestRequest,
  V1CreateTaskRequest,
  V1CreateTaskResponse,
  V1CreateTaskWithActivityTemplateRequest,
  V1CreateTaskWithActivityTemplateResponse,
  V1CreateTemplateRequest,
  V1CreateTemplateResponse,
  V1CreateThirdPartyUserRequest,
  V1CreateTicketReq,
  V1CurrentUserInfo,
  V1DecreaseMPNotificationCountRequest,
  V1DecreaseMPNotificationCountResponse,
  V1GetActivityResponse,
  V1GetActivityTemplateForUserResponse,
  V1GetActivityUserStatusResponse,
  V1GetCollectionByUserIDResponse,
  V1GetCollectionResponse,
  V1GetLoginVerificationCode,
  V1GetLoginVerificationCodeRequest,
  V1GetMyTemplateResponse,
  V1GetReleasedTemplateResponse,
  V1GetStudioSettingsResponse,
  V1GetSubscriptionDiscountRequest,
  V1GetUnlimitedQRCodeRequest,
  V1GetUnlimitedQRCodeResponse,
  V1GetURLSchemeRequest,
  V1GetURLSchemeResponse,
  V1GetUserAnalyticsResponse,
  V1GetUserBasicInfoResponse,
  V1GetUserByThirdPartyUserUIDResponse,
  V1GetUserCoinInfoResponse,
  V1GetUserProfileResponse,
  V1ImagePromptResp,
  V1IsTemplateNameAvailableResponse,
  V1LikeCollectionResponse,
  V1LikeWorkRequest,
  V1ListActivitiesForHomePageResponse,
  V1ListActivityTemplateForUserResponse,
  V1ListAllCollectionResponse,
  V1ListBusyTaskResponse,
  V1ListCategoryResponse,
  V1ListCollectionsByUserIDResponse,
  V1ListLikeWorksByUserIDResponse,
  V1ListMyCollectionResponse,
  V1ListMyTaskGroupsResponse,
  V1ListMyTasksResponse,
  V1ListMyTemplatesResponse,
  V1ListNotificationResponse,
  V1ListOrdersResponse,
  V1ListReceivedDailyCheckInUsersResponse,
  V1ListReleasedTemplatesResponse,
  V1ListRewardRecordsResponse,
  V1ListRewardsResponse,
  V1ListSceneResponse,
  V1ListTasksByUserIDResponse,
  V1ListTasksResponse,
  V1ListTemplateCategoriesResponse,
  V1ListTicketsResponse,
  V1ListUnscoredWorkResponse,
  V1ListUserDiscountCodesResponse,
  V1ListUserInviteCodesResponse,
  V1ListWorkResponse,
  V1LoginByPhone,
  V1LoginByPhoneQCRequest,
  V1LoginByPhoneWithInviteCodeRequest,
  V1MPResetNotificationCountRequest,
  V1MPResetNotificationCountResponse,
  V1Notification,
  V1ProductInfo,
  V1ReceiveActivityRewardRequest,
  V1ReceiveActivityRewardResponse,
  V1ReceiveDailyCheckInRewardResponse,
  V1ReceiveRegistInvitationRewardResponse,
  V1ReceiveSubscribeInvitationRewardResponse,
  V1ReceiveTemplateHeatTargetRewardResponse,
  V1ReceiveTemplateSubmissionCountTargetRewardResponse,
  V1ReceiveTemplateSubmissionRewardResponse,
  V1ReceiveUserGenerationCountTargetRewardResponse,
  V1RedeemCodeToRewardResponse,
  V1RedeemCoinResponse,
  V1ReGenerateRequest,
  V1ReGenerateRequestForGuest,
  V1RegisterByPhone,
  V1Scene,
  V1SetIsPrivateRequest,
  V1SetWorkIsPrivateRequest,
  V1SubscribeProductRequest,
  V1Task,
  V1ThirdPartyReceiveDailyCheckInRewardRequest,
  V1ThirdPartyReceiveDailyCheckInRewardResponse,
  V1ThirdPartyReceiveFreeGiftRewardRequest,
  V1ThirdPartyReceiveFreeGiftRewardResponse,
  V1ThirdPartyReceiveOneTimeSupplementRequest,
  V1ThirdPartyReceiveOneTimeSupplementResponse,
  V1ThirdPartyReceiveSubscriptionRequest,
  V1ThirdPartyReceiveSubscriptionResponse,
  V1UnLikeCollectionResponse,
  V1UnLikeWorkRequest,
  V1UpdateCollectionRequest,
  V1UpdateCollectionResponse,
  V1UpdateConsumePriorityRequest,
  V1UpdateConsumePriorityResponse,
  V1UpdateStudioSettingsRequest,
  V1UpdateStudioSettingsResponse,
  V1UpdateUserInfoRequest,
  V1UpdateWorkScoreRequest,
  V1UpgradePlanInfo,
  V1UpgradeSubscriptionRequest,
  V1UploadFileResponse,
  V1VerifyInviteCodeRequest,
  V1WechatLoginRequest,
  V1WechatLoginResponse,
  V1Work,
} from "./data-contracts"
import { ContentType, HttpClient, RequestParams } from "./http-client"

export class V1<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags activity-活动
   * @name ListActivitiesForHomePage
   * @summary 首页列出活动;
   * @request GET:/v1/activities
   */
  listActivitiesForHomePage = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListActivitiesForHomePageResponse
      },
      any
    >({
      path: `/v1/activities`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags activityTemplate-活动类别
   * @name GetForUserActivityTemplate
   * @summary 用户获取活动类别详情;
   * @request GET:/v1/activity-template/:activityTemplateUID
   */
  getForUserActivityTemplate = (activityTemplateUid: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetActivityTemplateForUserResponse
      },
      any
    >({
      path: `/v1/activity-template/${activityTemplateUid}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags activityTemplate-活动类别
   * @name ListForUserActivityTemplate
   * @summary 用户获取类别列表;
   * @request GET:/v1/activity-templates
   */
  listForUserActivityTemplate = (
    query: {
      /** activityUID string, page int, pageSize int */
      q: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListActivityTemplateForUserResponse
      },
      any
    >({
      path: `/v1/activity-templates`,
      method: "GET",
      query: query,
      ...params,
    })
  /**
   * No description
   *
   * @tags activity-活动
   * @name GetActivity
   * @summary 读取单个活动信息;
   * @request GET:/v1/activity/:activityUID
   */
  getActivity = (activityUid: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetActivityResponse
      },
      any
    >({
      path: `/v1/activity/${activityUid}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags activity-活动
   * @name GetUserStatus
   * @summary 查询用户的活动参与状态;
   * @request GET:/v1/activity/:activityUID/userStatus
   */
  getUserStatus = (activityUid: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetActivityUserStatusResponse
      },
      any
    >({
      path: `/v1/activity/${activityUid}/userStatus`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags admin
   * @name Dumpdata
   * @summary dump data
   * @request GET:/v1/admin/dump
   */
  dumpdata = (
    query?: {
      /** date=2019-01-01, default is today */
      date?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<any, any>({
      path: `/v1/admin/dump`,
      method: "GET",
      query: query,
      ...params,
    })
  /**
   * @description 创建通知
   *
   * @tags adminNotification
   * @name CreateNotificationForAdmin
   * @summary createNotificationForAdmin
   * @request POST:/v1/admin/notifications
   */
  createNotificationForAdmin = (body: V1CreateNotificationReq, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/admin/notifications`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name ApplyInviteCode
   * @summary applyInviteCode
   * @request POST:/v1/applyInviteCode
   */
  applyInviteCode = (body: V1ApplyInviteCodeRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/applyInviteCode`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags category-分类
   * @name ListCategorys
   * @summary listCategorys
   * @request GET:/v1/categories
   */
  listCategorys = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** 是否在评分处隐藏 */
      hideInScore?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListCategoryResponse
      },
      any
    >({
      path: `/v1/categories`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags category-分类
   * @name GetCategory
   * @summary getCategory
   * @request GET:/v1/categories/{id}
   */
  getCategory = (id: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1Category
      },
      any
    >({
      path: `/v1/categories/${id}`,
      method: "GET",
      ...params,
    })
  /**
   * @description 检查用户是否登录
   *
   * @tags user
   * @name CheckLogin
   * @summary CheckLogin
   * @request GET:/v1/checkLogin
   */
  checkLogin = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CheckLoginResponse
      },
      any
    >({
      path: `/v1/checkLogin`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags coin
   * @name RedeemCoin
   * @summary 金币兑换权益
   * @request GET:/v1/coinRedeem/:id/redeem
   */
  redeemCoin = (id: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1RedeemCoinResponse
      },
      any
    >({
      path: `/v1/coinRedeem/${id}/redeem`,
      method: "GET",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags coin
   * @name ListCoinRedeems
   * @summary 查询金币兑换权益列表
   * @request GET:/v1/coinRedeem/list
   */
  listCoinRedeems = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CoinRedeemDesc[]
      },
      any
    >({
      path: `/v1/coinRedeem/list`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags collection-相册
   * @name CreateCollection
   * @summary 创建相册;
   * @request POST:/v1/collection
   */
  createCollection = (body: V1CreateCollectionRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateCollectionResponse
      },
      any
    >({
      path: `/v1/collection`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags collection-相册
   * @name GetCollection
   * @summary 查询相册详情;
   * @request GET:/v1/collection/:collectionUID
   */
  getCollection = (
    collectionUid: string,
    query: {
      /** {'current':1,'pageSize':10,'sort':'highest|newest|popular'} */
      q: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetCollectionResponse
      },
      any
    >({
      path: `/v1/collection/${collectionUid}`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags collection-相册
   * @name UpdateCollection
   * @summary 更新相册;
   * @request PUT:/v1/collection/:collectionUID
   */
  updateCollection = (
    collectionUid: string,
    body: V1UpdateCollectionRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UpdateCollectionResponse
      },
      any
    >({
      path: `/v1/collection/${collectionUid}`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags collection-相册
   * @name LikeCollection
   * @summary 收藏相册;
   * @request PUT:/v1/collection/:collectionUID/like
   */
  likeCollection = (collectionUid: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1LikeCollectionResponse
      },
      any
    >({
      path: `/v1/collection/${collectionUid}/like`,
      method: "PUT",
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags collection-相册
   * @name UnlikeCollection
   * @summary 取消收藏相册;
   * @request DELETE:/v1/collection/:collectionUID/like
   */
  unlikeCollection = (collectionUid: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UnLikeCollectionResponse
      },
      any
    >({
      path: `/v1/collection/${collectionUid}/like`,
      method: "DELETE",
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags collection-相册
   * @name BatchDeleteCollection
   * @summary 批量删除相册;
   * @request POST:/v1/collection/batchDelete
   */
  batchDeleteCollection = (body: V1BatchDeleteCollectionRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1BatchDeleteCollectionResponse
      },
      any
    >({
      path: `/v1/collection/batchDelete`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags collection-相册
   * @name ListAllCollection
   * @summary 查询作品中心相册列表;
   * @request GET:/v1/collections
   */
  listAllCollection = (
    query: {
      /** query */
      q: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListAllCollectionResponse
      },
      any
    >({
      path: `/v1/collections`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags collection-相册
   * @name ListMyCollection
   * @summary 查询用户创建的相册列表;
   * @request GET:/v1/collections/mine
   */
  listMyCollection = (
    query: {
      /** {'current':1,'pageSize':10,'sort':'likeWorkAt|workCount|likeCount'} */
      q: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListMyCollectionResponse
      },
      any
    >({
      path: `/v1/collections/mine`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name GetCurrentUserInfo
   * @summary getCurrentUserInfo
   * @request GET:/v1/current
   */
  getCurrentUserInfo = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CurrentUserInfo
      },
      any
    >({
      path: `/v1/current`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags discountcode-优惠码
   * @name GetSubscriptionDiscount
   * @summary 根据优惠码获取订阅折扣
   * @request GET:/v1/discount-code/{code}/subscription
   */
  getSubscriptionDiscount = (code: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetSubscriptionDiscountRequest
      },
      any
    >({
      path: `/v1/discount-code/${code}/subscription`,
      method: "GET",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags discountcode-优惠码
   * @name ListUserDiscountCodes
   * @summary 拉取用户的优惠码数据
   * @request GET:/v1/discount-codes/user-owned
   */
  listUserDiscountCodes = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListUserDiscountCodesResponse
      },
      any
    >({
      path: `/v1/discount-codes/user-owned`,
      method: "GET",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name GetLoginVerificationCode
   * @summary GetLoginVerificationCode
   * @request POST:/v1/getLoginVerificationCode
   */
  getLoginVerificationCode = (
    body: V1GetLoginVerificationCodeRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetLoginVerificationCode
      },
      any
    >({
      path: `/v1/getLoginVerificationCode`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 访客创建任务
   *
   * @tags guestImagePrompt
   * @name GuestCreateImagePrompt
   * @summary guestCreateImagePrompt
   * @request POST:/v1/guest/imagePrompt
   */
  guestCreateImagePrompt = (body: V1CreateImagePromptForGuestRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateImagePromptResponse
      },
      any
    >({
      path: `/v1/guest/imagePrompt`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags guestFile
   * @name GuestUploadImageForPrompt
   * @summary uploadImageForPrompt 上传参考图片
   * @request POST:/v1/guest/imagePrompt/uploadImage
   */
  guestUploadImageForPrompt = (data: any, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UploadFileResponse
      },
      any
    >({
      path: `/v1/guest/imagePrompt/uploadImage`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags guestImagePrompt
   * @name GuestGetImagePrompt
   * @summary getImagePrompt-图生文
   * @request GET:/v1/guest/imagePrompt/{id}
   */
  guestGetImagePrompt = (id: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ImagePromptResp
      },
      any
    >({
      path: `/v1/guest/imagePrompt/${id}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags guestProfile
   * @name GuestGetUserProfile
   * @summary getUserProfile-获取用户信息
   * @request GET:/v1/guest/profile/{targetUserID}
   */
  guestGetUserProfile = (targetUserId: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetUserProfileResponse
      },
      any
    >({
      path: `/v1/guest/profile/${targetUserId}`,
      method: "GET",
      format: "json",
      ...params,
    })
  /**
   * @description 获取某个用户喜欢的作品
   *
   * @tags guestProfile
   * @name GuestListLikeWorksByUserId
   * @summary listLikeWorksByUserID-获取某个用户喜欢的作品
   * @request GET:/v1/guest/profile/{targetUserID}/likeWorks
   */
  guestListLikeWorksByUserId = (
    targetUserId: string,
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** query by prompt text */
      query?: string
      /** sort by highest, newest, popular; if not set, default is newest */
      sort?: string
      /** isCurrentUser */
      isCurrentUser?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListLikeWorksByUserIDResponse
      },
      any
    >({
      path: `/v1/guest/profile/${targetUserId}/likeWorks`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取某个用户的公开作品,按照输入参数进行分组
   *
   * @tags guestProfile
   * @name GuestListTasksByUserId
   * @summary ListTasksByUserID-获取某个用户的作品
   * @request GET:/v1/guest/profile/{targetUserID}/tasks
   */
  guestListTasksByUserId = (
    targetUserId: string,
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** query by prompt text */
      query?: string
      /** sort by highest, newest, popular; if not set, default is newest */
      sort?: string
      /** isCurrentUser */
      isCurrentUser?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListTasksByUserIDResponse
      },
      any
    >({
      path: `/v1/guest/profile/${targetUserId}/tasks`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取所有任务/作品
   *
   * @tags guestTask
   * @name GuestListAllTasks
   * @summary listAllTasks-[首页]-获取所有任务/作品
   * @request GET:/v1/guest/task/all
   */
  guestListAllTasks = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** query by prompt text */
      query?: string
      /** sort by highest, newest, popular; if not set, default is newest */
      sort?: string
      /** filter by categoryUID */
      category?: string
      /** filter by model */
      model?: string
      /** filter by has ReferencedImage */
      hasReferencedImage?: boolean
      /** filter by activityUID */
      activityUID?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListTasksResponse
      },
      any
    >({
      path: `/v1/guest/task/all`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 访客检查是否已创建过任务
   *
   * @tags guestTask
   * @name GuestCheckHasCreatedTask
   * @summary guestCheckHasCreatedTask
   * @request POST:/v1/guest/task/checkGuestCreated
   */
  guestCheckHasCreatedTask = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CheckGuestCreatedTaskResponse
      },
      any
    >({
      path: `/v1/guest/task/checkGuestCreated`,
      method: "POST",
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 访客创建任务
   *
   * @tags guestTask
   * @name GuestCreateTask
   * @summary guestCreateTask
   * @request POST:/v1/guest/task/create
   */
  guestCreateTask = (body: V1CreateTaskForGuestRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateTaskResponse
      },
      any
    >({
      path: `/v1/guest/task/create`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 图像重绘，作品中的seed不等于-1 才可以重绘
   *
   * @tags guestTask
   * @name GuestRegenerate
   * @summary ReGenerate
   * @request POST:/v1/guest/task/regenerate
   */
  guestRegenerate = (body: V1ReGenerateRequestForGuest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateTaskResponse
      },
      any
    >({
      path: `/v1/guest/task/regenerate`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags guestTask
   * @name GuestGetTask
   * @summary getTask-获取任务详情
   * @request GET:/v1/guest/tasks/{id}
   */
  guestGetTask = (id: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1Task
      },
      any
    >({
      path: `/v1/guest/tasks/${id}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags guestFile
   * @name GuestUploadFile
   * @summary guestUploadFile-上传参考图
   * @request POST:/v1/guest/uploadFile
   */
  guestUploadFile = (data: any, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/guest/uploadFile`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags template-模板
   * @name GetTemplateForHomePage
   * @summary 获取单个首页模板;
   * @request GET:/v1/homepage/template/:templateID
   */
  getTemplateForHomePage = (templateId: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetReleasedTemplateResponse
      },
      any
    >({
      path: `/v1/homepage/template/${templateId}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags template-模板
   * @name ListTemplatesForHomePage
   * @summary 获取首页模板列表;
   * @request GET:/v1/homepage/templates
   */
  listTemplatesForHomePage = (
    query: {
      /** page */
      page: number
      /** page size */
      pageSize: number
      /** is recommand */
      isRecommand?: boolean
      /** category */
      category?: string
      /** model */
      model?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListReleasedTemplatesResponse
      },
      any
    >({
      path: `/v1/homepage/templates`,
      method: "GET",
      query: query,
      ...params,
    })
  /**
   * No description
   *
   * @tags ImagePrompt-图片转Prompt, thirdParty
   * @name CreateImagePrompt
   * @summary createImagePrompt-图生文
   * @request POST:/v1/imagePrompt
   */
  createImagePrompt = (body: V1CreateImagePromptRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateImagePromptResponse
      },
      any
    >({
      path: `/v1/imagePrompt`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags ImagePrompt-图片转Prompt
   * @name UploadImageForPrompt
   * @summary uploadImageForPrompt 上传参考图片
   * @request POST:/v1/imagePrompt/uploadImage
   */
  uploadImageForPrompt = (data: any, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UploadFileResponse
      },
      any
    >({
      path: `/v1/imagePrompt/uploadImage`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags ImagePrompt-图片转Prompt, thirdParty
   * @name GetImagePrompt
   * @summary getImagePrompt-图生文
   * @request GET:/v1/imagePrompt/{id}
   */
  getImagePrompt = (id: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ImagePromptResp
      },
      any
    >({
      path: `/v1/imagePrompt/${id}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags invitecode-邀请码
   * @name ListUserInviteCodes
   * @summary 拉取用户可分发的邀请码数据
   * @request GET:/v1/invite-codes/user-owned
   */
  listUserInviteCodes = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListUserInviteCodesResponse
      },
      any
    >({
      path: `/v1/invite-codes/user-owned`,
      method: "GET",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name LoginByPhone
   * @summary LoginByPhone
   * @request POST:/v1/loginByPhone
   */
  loginByPhone = (body: V1LoginByPhone, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CurrentUserInfo
      },
      any
    >({
      path: `/v1/loginByPhone`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name LoginByPhoneQc
   * @summary LoginByPhoneQC
   * @request POST:/v1/loginByPhone/qc
   */
  loginByPhoneQc = (body: V1LoginByPhoneQCRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CurrentUserInfo
      },
      any
    >({
      path: `/v1/loginByPhone/qc`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name LoginByPhoneWithInviteCode
   * @summary LoginByPhoneWithInviteCode
   * @request POST:/v1/loginByPhoneWithInviteCode
   */
  loginByPhoneWithInviteCode = (
    body: V1LoginByPhoneWithInviteCodeRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CurrentUserInfo
      },
      any
    >({
      path: `/v1/loginByPhoneWithInviteCode`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name Logout
   * @summary logout
   * @request GET:/v1/logout
   */
  logout = (params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/logout`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags miniprogram
   * @name GetUrlScheme
   * @summary getURLScheme
   * @request POST:/v1/miniprogram/wechat/scheme
   */
  getUrlScheme = (body: V1GetURLSchemeRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetURLSchemeResponse
      },
      any
    >({
      path: `/v1/miniprogram/wechat/scheme`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取无限制微信小程序码
   *
   * @tags miniprogram
   * @name GetUnlimitedQrCode
   * @summary getUnlimitedQRCode
   * @request POST:/v1/miniprogram/wechat/unlimited-qrcode
   */
  getUnlimitedQrCode = (body: V1GetUnlimitedQRCodeRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetUnlimitedQRCodeResponse
      },
      any
    >({
      path: `/v1/miniprogram/wechat/unlimited-qrcode`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 微信小程序发送订阅消息
   *
   * @tags user
   * @name DecreaseMpNotificationCount
   * @summary DecreaseMPNotificationCount
   * @request POST:/v1/mp/notification/count/decrease
   */
  decreaseMpNotificationCount = (
    body: V1DecreaseMPNotificationCountRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1DecreaseMPNotificationCountResponse
      },
      any
    >({
      path: `/v1/mp/notification/count/decrease`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 微信小程序发送订阅消息
   *
   * @tags user
   * @name MpResetNotificationCounts
   * @summary MPResetNotificationCounts
   * @request PUT:/v1/mp/notification/counts/reset
   */
  mpResetNotificationCounts = (
    body: V1MPResetNotificationCountRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1MPResetNotificationCountResponse
      },
      any
    >({
      path: `/v1/mp/notification/counts/reset`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ListReceivedDailyCheckInUsers
   * @summary 获取指定时间段内签到用户
   * @request GET:/v1/mp/receive/daily-checkin/list
   */
  listReceivedDailyCheckInUsers = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListReceivedDailyCheckInUsersResponse
      },
      any
    >({
      path: `/v1/mp/receive/daily-checkin/list`,
      method: "GET",
      format: "json",
      ...params,
    })
  /**
   * @description 设置通知全部已读
   *
   * @tags notification
   * @name ReadAllNotification
   * @summary readAllNotification
   * @request PUT:/v1/notification/readAll
   */
  readAllNotification = (params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/notification/readAll`,
      method: "PUT",
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags notification
   * @name ListNotifications
   * @summary listNotifications
   * @request GET:/v1/notifications
   */
  listNotifications = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListNotificationResponse
      },
      any
    >({
      path: `/v1/notifications`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取通知
   *
   * @tags notification
   * @name GetNotification
   * @summary getNotification
   * @request GET:/v1/notifications/{id}
   */
  getNotification = (id: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1Notification
      },
      any
    >({
      path: `/v1/notifications/${id}`,
      method: "GET",
      ...params,
    })
  /**
   * @description 设置通知已读
   *
   * @tags notification
   * @name ReadNotification
   * @summary readNotification
   * @request PUT:/v1/notifications/{id}/read
   */
  readNotification = (id: string, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/notifications/${id}/read`,
      method: "PUT",
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags orders
   * @name GetOrderById
   * @summary 获取订单详情GetOrderById
   * @request GET:/v1/orders/:order_id
   */
  getOrderById = (orderId: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: RepoOrder
      },
      any
    >({
      path: `/v1/orders/${orderId}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags orders
   * @name GetOrderList
   * @summary 获取订单列表
   * @request GET:/v1/orders/list
   */
  getOrderList = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListOrdersResponse
      },
      any
    >({
      path: `/v1/orders/list`,
      method: "GET",
      query: query,
      ...params,
    })
  /**
   * No description
   *
   * @tags product
   * @name GetProductList
   * @summary 获取套餐方案列表
   * @request GET:/v1/product/list
   */
  getProductList = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ProductInfo[]
      },
      any
    >({
      path: `/v1/product/list`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags product
   * @name GetUpgradePlanInfo
   * @summary 获取升级套餐方案的信息
   * @request POST:/v1/product/{product_id}/getUpgradePlanInfo
   */
  getUpgradePlanInfo = (productId: number, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UpgradePlanInfo
      },
      any
    >({
      path: `/v1/product/${productId}/getUpgradePlanInfo`,
      method: "POST",
      ...params,
    })
  /**
   * No description
   *
   * @tags product
   * @name SubscribeProduct
   * @summary 订阅套餐方案
   * @request POST:/v1/product/{product_id}/subscribe
   */
  subscribeProduct = (
    productId: number,
    body: V1SubscribeProductRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateOrderResp
      },
      any
    >({
      path: `/v1/product/${productId}/subscribe`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags product
   * @name UpgradePlan
   * @summary 升级套餐方案
   * @request POST:/v1/product/{product_id}/upgradePlan
   */
  upgradePlan = (
    productId: number,
    body: V1UpgradeSubscriptionRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateOrderResp
      },
      any
    >({
      path: `/v1/product/${productId}/upgradePlan`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags profile
   * @name GetUserProfile
   * @summary getUserProfile-获取用户信息
   * @request GET:/v1/profile/{targetUserID}
   */
  getUserProfile = (
    targetUserId: string,
    query?: {
      /** isCurrentUser */
      isCurrentUser?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetUserProfileResponse
      },
      any
    >({
      path: `/v1/profile/${targetUserId}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    })
  /**
   * @description 查询其他用户个人中心相册详情
   *
   * @tags profile
   * @name GetCollectionByUserId
   * @summary GetCollectionByUserID-获取其他用户个人中心相册详情
   * @request GET:/v1/profile/{targetUserID}/collection/{collectionUID}
   */
  getCollectionByUserId = (
    collectionUid: string,
    targetUserId: string,
    query: {
      /** {'current':1,'pageSize':10,'sort':'highest|newest|popular'} */
      q: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetCollectionByUserIDResponse
      },
      any
    >({
      path: `/v1/profile/${targetUserId}/collection/${collectionUid}`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 查询其他用户个人中心相册页面
   *
   * @tags profile
   * @name ListCollectionsByUserId
   * @summary ListCollectionsByUserID-获取某个用户创建的公开相册和收藏的相册
   * @request GET:/v1/profile/{targetUserID}/collections
   */
  listCollectionsByUserId = (
    targetUserId: string,
    query: {
      /** {'current':1,'pageSize':10,'sort':'likeWorkAt|workCount|likeCount'} */
      q: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListCollectionsByUserIDResponse
      },
      any
    >({
      path: `/v1/profile/${targetUserId}/collections`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取某个用户喜欢的作品
   *
   * @tags profile
   * @name ListLikeWorksByUserId
   * @summary listLikeWorksByUserID-获取某个用户喜欢的作品
   * @request GET:/v1/profile/{targetUserID}/likeWorks
   */
  listLikeWorksByUserId = (
    targetUserId: string,
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** query by prompt text */
      query?: string
      /** sort by highest, newest, popular; if not set, default is newest */
      sort?: string
      /** isCurrentUser */
      isCurrentUser?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListLikeWorksByUserIDResponse
      },
      any
    >({
      path: `/v1/profile/${targetUserId}/likeWorks`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取某个用户的公开作品,按照输入参数进行分组
   *
   * @tags profile
   * @name ListTasksByUserId
   * @summary ListTasksByUserID-获取某个用户的作品
   * @request GET:/v1/profile/{targetUserID}/tasks
   */
  listTasksByUserId = (
    targetUserId: string,
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** query by prompt text */
      query?: string
      /** sort by highest, newest, popular; if not set, default is newest */
      sort?: string
      /** isCurrentUser */
      isCurrentUser?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListTasksByUserIDResponse
      },
      any
    >({
      path: `/v1/profile/${targetUserId}/tasks`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 兑换码兑换奖励
   *
   * @tags discountcode-优惠码
   * @name RedeemCodeToReward
   * @summary 兑换码兑换奖励
   * @request POST:/v1/redeem-code/{code}/redeem
   */
  redeemCodeToReward = (code: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1RedeemCodeToRewardResponse
      },
      any
    >({
      path: `/v1/redeem-code/${code}/redeem`,
      method: "POST",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name RegisterByPhone
   * @summary RegisterByPhone
   * @request POST:/v1/registerByPhone
   */
  registerByPhone = (body: V1RegisterByPhone, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CurrentUserInfo
      },
      any
    >({
      path: `/v1/registerByPhone`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ReceiveActivityReward
   * @summary 领取活动奖励
   * @request POST:/v1/reward/activity
   */
  receiveActivityReward = (body: V1ReceiveActivityRewardRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ReceiveActivityRewardResponse
      },
      any
    >({
      path: `/v1/reward/activity`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ReceiveDailyCheckInReward
   * @summary 领取每日签到奖励
   * @request POST:/v1/reward/daily-checkin
   */
  receiveDailyCheckInReward = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ReceiveDailyCheckInRewardResponse
      },
      any
    >({
      path: `/v1/reward/daily-checkin`,
      method: "POST",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ReceiveUserGenerationCountTargetReward
   * @summary 领取生成次数达标奖励
   * @request POST:/v1/reward/generation-target
   */
  receiveUserGenerationCountTargetReward = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ReceiveUserGenerationCountTargetRewardResponse
      },
      any
    >({
      path: `/v1/reward/generation-target`,
      method: "POST",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ListRewardRecords
   * @summary 查询奖励领取记录
   * @request GET:/v1/reward/records
   */
  listRewardRecords = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListRewardRecordsResponse
      },
      any
    >({
      path: `/v1/reward/records`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ReceiveRegistInvitationReward
   * @summary 领取邀请注册奖励
   * @request POST:/v1/reward/regist-invitation
   */
  receiveRegistInvitationReward = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ReceiveRegistInvitationRewardResponse
      },
      any
    >({
      path: `/v1/reward/regist-invitation`,
      method: "POST",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ReceiveSubscriptionInvitationReward
   * @summary 领取邀请订阅奖励
   * @request POST:/v1/reward/subscription-invitation
   */
  receiveSubscriptionInvitationReward = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ReceiveSubscribeInvitationRewardResponse
      },
      any
    >({
      path: `/v1/reward/subscription-invitation`,
      method: "POST",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ReceiveTemplateHeatTargetReward
   * @summary 领取热度达标奖励
   * @request POST:/v1/reward/template-heat-target
   */
  receiveTemplateHeatTargetReward = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ReceiveTemplateHeatTargetRewardResponse
      },
      any
    >({
      path: `/v1/reward/template-heat-target`,
      method: "POST",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ReceiveTemplateSubmissionReward
   * @summary 领取模板提交奖励
   * @request POST:/v1/reward/template-submission
   */
  receiveTemplateSubmissionReward = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ReceiveTemplateSubmissionRewardResponse
      },
      any
    >({
      path: `/v1/reward/template-submission`,
      method: "POST",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ReceiveTemplateSubmissionCountTargetReward
   * @summary 领取提交次数达标奖励
   * @request POST:/v1/reward/template-submission-target
   */
  receiveTemplateSubmissionCountTargetReward = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ReceiveTemplateSubmissionCountTargetRewardResponse
      },
      any
    >({
      path: `/v1/reward/template-submission-target`,
      method: "POST",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags reward-奖励
   * @name ListRewards
   * @summary 拉取用户个人中心页奖励数据
   * @request GET:/v1/rewards
   */
  listRewards = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListRewardsResponse
      },
      any
    >({
      path: `/v1/rewards`,
      method: "GET",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags scene-场景-Deprecated
   * @name ListScenes
   * @summary listScenes
   * @request GET:/v1/scenes
   */
  listScenes = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** query by prompt */
      query?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListSceneResponse
      },
      any
    >({
      path: `/v1/scenes`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags scene-场景-Deprecated
   * @name GetScene
   * @summary getScene
   * @request GET:/v1/scenes/{id}
   */
  getScene = (id: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1Scene
      },
      any
    >({
      path: `/v1/scenes/${id}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags work
   * @name UpdateWorkScore
   * @summary UpdateWorkScore
   * @request POST:/v1/sdlfkjsoiwerwexcyvodiu/work/score
   */
  updateWorkScore = (body: V1UpdateWorkScoreRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/sdlfkjsoiwerwexcyvodiu/work/score`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags work
   * @name ListUnScoredWorks
   * @summary ListUnScoredWorks
   * @request GET:/v1/sdlfkjsoiwerwexcyvodiu/work/unscored
   */
  listUnScoredWorks = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListUnscoredWorkResponse
      },
      any
    >({
      path: `/v1/sdlfkjsoiwerwexcyvodiu/work/unscored`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags studio-工作室
   * @name GetStudioSettings
   * @summary 获取 Studio 设置;
   * @request GET:/v1/studio/settings
   */
  getStudioSettings = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetStudioSettingsResponse
      },
      any
    >({
      path: `/v1/studio/settings`,
      method: "GET",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags studio-工作室
   * @name UpdateStudioSettings
   * @summary 更新 Studio 设置;
   * @request PUT:/v1/studio/settings
   */
  updateStudioSettings = (body: V1UpdateStudioSettingsRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UpdateStudioSettingsResponse
      },
      any
    >({
      path: `/v1/studio/settings`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取所有任务/作品
   *
   * @tags task
   * @name ListAllTasks
   * @summary listAllTasks-[首页]-获取所有任务/作品
   * @request GET:/v1/task/all
   */
  listAllTasks = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** query by prompt text */
      query?: string
      /** sort by highest, newest, popular; if not set, default is newest */
      sort?: string
      /** is zhangjiang user created task */
      isZhangjiang?: boolean
      /** is zhangjiangV2 user created task */
      isZhangjiangV2?: boolean
      /** filter by categoryUID */
      category?: string
      /** filter by model */
      model?: string
      /** filter by has ReferencedImage */
      hasReferencedImage?: boolean
      /** filter by activityUID */
      activityUID?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListTasksResponse
      },
      any
    >({
      path: `/v1/task/all`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 批量设置作品是否私有
   *
   * @tags task
   * @name BatchSetIsPrivate
   * @summary batchSetIsPrivate
   * @request PUT:/v1/task/batchSetIsPrivate
   * @deprecated
   */
  batchSetIsPrivate = (body: V1BatchSetIsPrivateRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/task/batchSetIsPrivate`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取当前用户的正在运行或等待中任务数量和信息。
   *
   * @tags task
   * @name ListBusyTasks
   * @summary listBusyTasks
   * @request GET:/v1/task/busy
   */
  listBusyTasks = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListBusyTaskResponse
      },
      any
    >({
      path: `/v1/task/busy`,
      method: "GET",
      ...params,
    })
  /**
   * @description 图像重绘，作品中的seed不等于-1 才可以重绘
   *
   * @tags task
   * @name Regenerate
   * @summary ReGenerate
   * @request POST:/v1/task/regenerate
   */
  regenerate = (body: V1ReGenerateRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateTaskResponse
      },
      any
    >({
      path: `/v1/task/regenerate`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取我的作品,按照输入参数进行分组
   *
   * @tags task, thirdParty
   * @name ListMyTaskGroups
   * @summary ListMyTaskGroups-[作品中心]-获取我的任务/作品
   * @request GET:/v1/taskGroups/mine
   */
  listMyTaskGroups = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** query by prompt */
      query?: string
      /** sort by highest, newest, popular; if not set, default is newest */
      sort?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListMyTaskGroupsResponse
      },
      any
    >({
      path: `/v1/taskGroups/mine`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 免费用户:创建任务优先级使用 high;  付费用户，创建任务优先级可以选择 high | low; 可以根据 userInfo.accounts 判断用户的权益余额。
   *
   * @tags task
   * @name CreateTask
   * @summary createTask
   * @request POST:/v1/tasks
   */
  createTask = (body: V1CreateTaskRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateTaskResponse
      },
      any
    >({
      path: `/v1/tasks`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 免费用户:创建任务优先级使用 high;  付费用户，创建任务优先级可以选择 high | low; 可以根据 userInfo.accounts 判断用户的权益余额。
   *
   * @tags task
   * @name CreateTaskWithActivityTemplate
   * @summary createTaskForActivityTemplate
   * @request POST:/v1/tasks/activity-template
   */
  createTaskWithActivityTemplate = (
    body: V1CreateTaskWithActivityTemplateRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateTaskWithActivityTemplateResponse
      },
      any
    >({
      path: `/v1/tasks/activity-template`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取我的任务/作品,以task维度展示作品
   *
   * @tags task, thirdParty
   * @name ListMyTasks
   * @summary ListMyTasks-[作品中心]-获取我的任务/作品,以task维度展示作品
   * @request GET:/v1/tasks/mine
   */
  listMyTasks = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
      /** query by prompt */
      query?: string
      /** sort by highest, newest, popular; if not set, default is newest */
      sort?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListMyTasksResponse
      },
      any
    >({
      path: `/v1/tasks/mine`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 张江一创图片查询
   *
   * @tags task
   * @name ListForZhangJiangV1
   * @summary listForZhangJiangV1-张江一创图片查询
   * @request GET:/v1/tasks/zhangjiang1
   */
  listForZhangJiangV1 = (
    query?: {
      /** limit */
      limit?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListTasksResponse
      },
      any
    >({
      path: `/v1/tasks/zhangjiang1`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 张江二创图片查询
   *
   * @tags task
   * @name ListForZhangJiangV2
   * @summary listForZhangJiangV2-张江二创图片查询
   * @request GET:/v1/tasks/zhangjiang2
   */
  listForZhangJiangV2 = (
    query?: {
      /** limit */
      limit?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListTasksResponse
      },
      any
    >({
      path: `/v1/tasks/zhangjiang2`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags task, thirdParty
   * @name GetTask
   * @summary getTask-获取任务详情
   * @request GET:/v1/tasks/{id}
   */
  getTask = (id: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1Task
      },
      any
    >({
      path: `/v1/tasks/${id}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags task
   * @name BindCategories
   * @summary BindCategories-[作品绑定分类]
   * @request POST:/v1/tasks/{id}/categories
   */
  bindCategories = (id: string, body: V1BindCategoryRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/tasks/${id}/categories`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags task
   * @name SetIsPrivate
   * @summary SetIsPrivate
   * @request PUT:/v1/tasks/{id}/isPrivate
   */
  setIsPrivate = (id: string, body: V1SetIsPrivateRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/tasks/${id}/isPrivate`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags template-模板
   * @name CreateTemplate
   * @summary 创建我的模板;
   * @request POST:/v1/template
   */
  createTemplate = (body: V1CreateTemplateRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateTemplateResponse
      },
      any
    >({
      path: `/v1/template`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags template-模板
   * @name GetMyTemplate
   * @summary 获取单个我的模板;
   * @request GET:/v1/template/:templateID
   */
  getMyTemplate = (templateId: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetMyTemplateResponse
      },
      any
    >({
      path: `/v1/template/${templateId}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags template-模板
   * @name ListTemplateCategories
   * @summary 获取模板分类;
   * @request GET:/v1/template/categories
   */
  listTemplateCategories = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListTemplateCategoriesResponse
      },
      any
    >({
      path: `/v1/template/categories`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags template-模板
   * @name IsTemplateNameAvailable
   * @summary 检测模板名是否可用;
   * @request GET:/v1/template/name/:templateName/available
   */
  isTemplateNameAvailable = (templateName: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1IsTemplateNameAvailableResponse
      },
      any
    >({
      path: `/v1/template/name/${templateName}/available`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags template-模板
   * @name ListMyTemplates
   * @summary 获取我的模板;
   * @request GET:/v1/templates
   */
  listMyTemplates = (
    query: {
      /** page */
      page: number
      /** page size */
      pageSize: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListMyTemplatesResponse
      },
      any
    >({
      path: `/v1/templates`,
      method: "GET",
      query: query,
      ...params,
    })
  /**
   * No description
   *
   * @tags thirdParty
   * @name ThirdpartyReceiveDailyCheckInReward
   * @summary thirdpartyReceiveDailyCheckInReward - 发放每日签到奖励;
   * @request POST:/v1/thirdparty/reward/daily-checkin
   */
  thirdpartyReceiveDailyCheckInReward = (
    body: V1ThirdPartyReceiveDailyCheckInRewardRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ThirdPartyReceiveDailyCheckInRewardResponse
      },
      any
    >({
      path: `/v1/thirdparty/reward/daily-checkin`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags thirdParty
   * @name ReceiveFreeGiftReward
   * @summary receiveFreeGiftReward - 发放免费奖励;
   * @request PUT:/v1/thirdparty/reward/free-gift
   */
  receiveFreeGiftReward = (
    body: V1ThirdPartyReceiveFreeGiftRewardRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ThirdPartyReceiveFreeGiftRewardResponse
      },
      any
    >({
      path: `/v1/thirdparty/reward/free-gift`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags thirdParty
   * @name ReceiveSubscriptionReward
   * @summary receiveSubscriptionReward - 发放订阅权益;
   * @request PUT:/v1/thirdparty/reward/subscription
   */
  receiveSubscriptionReward = (
    body: V1ThirdPartyReceiveSubscriptionRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ThirdPartyReceiveSubscriptionResponse
      },
      any
    >({
      path: `/v1/thirdparty/reward/subscription`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags thirdParty
   * @name ReceiveOneTimeSupplementReward
   * @summary receiveOneTimeSupplementReward - 发放一次性补充包奖励;
   * @request PUT:/v1/thirdparty/reward/supplement
   */
  receiveOneTimeSupplementReward = (
    body: V1ThirdPartyReceiveOneTimeSupplementRequest,
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ThirdPartyReceiveOneTimeSupplementResponse
      },
      any
    >({
      path: `/v1/thirdparty/reward/supplement`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags thirdParty
   * @name CreateTaskForThirdParty
   * @summary createTask-文生图创作
   * @request POST:/v1/thirdparty/tasks
   */
  createTaskForThirdParty = (body: V1CreateTaskRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CreateTaskResponse
      },
      any
    >({
      path: `/v1/thirdparty/tasks`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 根据第三方平台用户UID获取用户信息
   *
   * @tags thirdParty
   * @name GetUserByQcUserUid
   * @summary GetUserByQCUserUID-根据第三方平台用户UID获取用户信息
   * @request GET:/v1/thirdparty/user/{thirdPartyUserUID}
   */
  getUserByQcUserUid = (
    thirdPartyUserUid: string,
    query: {
      /** thirdPartyUID */
      thirdPartyUID: string
    },
    params: RequestParams = {}
  ) =>
    this.request<V1GetUserByThirdPartyUserUIDResponse, any>({
      path: `/v1/thirdparty/user/${thirdPartyUserUid}`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 为第三方平台创建用户
   *
   * @tags thirdParty
   * @name CreateThirdPartyUser
   * @summary createThirdPartyUser-为第三方平台创建用户
   * @request POST:/v1/thirdparty/users
   */
  createThirdPartyUser = (body: V1CreateThirdPartyUserRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/thirdparty/users`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags ticket
   * @name UploadImageForTicket
   * @summary uploadImageForTicket 上传工单中的图片
   * @request POST:/v1/ticket/uploadImage
   */
  uploadImageForTicket = (data: any, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UploadFileResponse
      },
      any
    >({
      path: `/v1/ticket/uploadImage`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags ticket
   * @name ListTickets
   * @summary listTickets
   * @request GET:/v1/tickets
   */
  listTickets = (
    query?: {
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListTicketsResponse
      },
      any
    >({
      path: `/v1/tickets`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 创建通知
   *
   * @tags ticket
   * @name CreateTicket
   * @summary createTicket
   * @request POST:/v1/tickets
   */
  createTicket = (body: V1CreateTicketReq, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/tickets`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 设置消耗余额优先级
   *
   * @tags user
   * @name UpdateConsumePriority
   * @summary updateConsumePriority
   * @request POST:/v1/updateConsumePriority
   */
  updateConsumePriority = (body: V1UpdateConsumePriorityRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UpdateConsumePriorityResponse
      },
      any
    >({
      path: `/v1/updateConsumePriority`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags file, thirdParty
   * @name UploadFile
   * @summary uploadFile-上传参考图
   * @request POST:/v1/uploadFile
   */
  uploadFile = (data: any, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/uploadFile`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name UpdateAvatar
   * @summary updateAvatar
   * @request POST:/v1/user/avatar
   */
  updateAvatar = (data: any, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UploadFileResponse
      },
      any
    >({
      path: `/v1/user/avatar`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name GetUserBasicInfo
   * @summary getUserBasicInfo
   * @request GET:/v1/user/basicinfo
   */
  getUserBasicInfo = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetUserBasicInfoResponse
      },
      any
    >({
      path: `/v1/user/basicinfo`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags coin
   * @name GetUserCoinInfo
   * @summary 查询用户金币信息
   * @request GET:/v1/user/coinInfo
   */
  getUserCoinInfo = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetUserCoinInfoResponse
      },
      any
    >({
      path: `/v1/user/coinInfo`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name UpdateCover
   * @summary updateCover
   * @request POST:/v1/user/cover
   */
  updateCover = (data: any, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1UploadFileResponse
      },
      any
    >({
      path: `/v1/user/cover`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name UpdateUserInfo
   * @summary updateUserInfo
   * @request PUT:/v1/user/info
   */
  updateUserInfo = (body: V1UpdateUserInfoRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CurrentUserInfo
      },
      any
    >({
      path: `/v1/user/info`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags userstats-用户统计
   * @name GetUserStats
   * @summary 获取用户统计数据
   * @request GET:/v1/user/stats
   */
  getUserStats = (params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1GetUserAnalyticsResponse
      },
      any
    >({
      path: `/v1/user/stats`,
      method: "GET",
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags user
   * @name VerifyInviteCode
   * @summary VerifyInviteCode
   * @request POST:/v1/verifyInviteCode
   */
  verifyInviteCode = (body: V1VerifyInviteCodeRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1CurrentUserInfo
      },
      any
    >({
      path: `/v1/verifyInviteCode`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 微信小程序登录
   *
   * @tags user
   * @name WechatLoginHandler
   * @summary WechatLoginHandler
   * @request POST:/v1/wechat/login
   */
  wechatLoginHandler = (body: V1WechatLoginRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1WechatLoginResponse
      },
      any
    >({
      path: `/v1/wechat/login`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 批量删除作品
   *
   * @tags work
   * @name BatchDeleteWorks
   * @summary batchDeleteWorks
   * @request POST:/v1/work/batchDelete
   */
  batchDeleteWorks = (ids: string[], params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/work/batchDelete`,
      method: "POST",
      body: ids,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 批量点赞作品
   *
   * @tags work
   * @name BatchLikeWorks
   * @summary batchLikeWorks
   * @request POST:/v1/work/batchLike
   */
  batchLikeWorks = (body: V1BatchLikeWorkRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/work/batchLike`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags work
   * @name BatchSetWorksIsPrivate
   * @summary batchSetWorksIsPrivate
   * @request PUT:/v1/work/batchSetIsPrivate
   */
  batchSetWorksIsPrivate = (body: V1BatchSetWorksIsPrivateRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/work/batchSetIsPrivate`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 批量取消点赞作品
   *
   * @tags work
   * @name BatchUnlikeWorks
   * @summary batchUnlikeWorks
   * @request POST:/v1/work/batchUnlike
   */
  batchUnlikeWorks = (body: V1BatchUnLikeWorkRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/work/batchUnlike`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取我的作品
   *
   * @tags work, thirdParty
   * @name ListMyWorks
   * @summary listMyWorks-获取我的作品
   * @request GET:/v1/work/mine
   */
  listMyWorks = (
    query?: {
      /** query by prompt text */
      query?: string
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListWorkResponse
      },
      any
    >({
      path: `/v1/work/mine`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 获取我喜欢的作品
   *
   * @tags work
   * @name ListMyLikeWorks
   * @summary listMyLikeWorks-[作品中心]-获取我喜欢的作品
   * @request GET:/v1/work/mylike
   */
  listMyLikeWorks = (
    query?: {
      /** query by prompt text */
      query?: string
      /** current */
      current?: number
      /** pageSize */
      pageSize?: number
    },
    params: RequestParams = {}
  ) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1ListWorkResponse
      },
      any
    >({
      path: `/v1/work/mylike`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 新版的举报作品,开放给普通用户和管理用户
   *
   * @tags work
   * @name AbuseReport
   * @summary 新版的举报作品,开放给普通用户和管理用户
   * @request POST:/v1/works/abuseReport
   */
  abuseReport = (body: V1AbuseReportSubmitReq, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/works/abuseReport`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * No description
   *
   * @tags work
   * @name GetWork
   * @summary getWork
   * @request GET:/v1/works/{id}
   */
  getWork = (id: string, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V1Work
      },
      any
    >({
      path: `/v1/works/${id}`,
      method: "GET",
      ...params,
    })
  /**
   * No description
   *
   * @tags work
   * @name SetWorkIsPrivate
   * @summary setWorkIsPrivate
   * @request PUT:/v1/works/{id}/isPrivate
   */
  setWorkIsPrivate = (id: string, body: V1SetWorkIsPrivateRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/works/${id}/isPrivate`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 作品点赞
   *
   * @tags work
   * @name LikeWork
   * @summary likeWork
   * @request PUT:/v1/works/{id}/like
   */
  likeWork = (id: string, body: V1LikeWorkRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/works/${id}/like`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 作品取消点赞
   *
   * @tags work
   * @name UnlikeWork
   * @summary unlikeWork
   * @request DELETE:/v1/works/{id}/like
   */
  unlikeWork = (id: string, body: V1UnLikeWorkRequest, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/works/${id}/like`,
      method: "DELETE",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
  /**
   * @description 举报作品
   *
   * @tags work
   * @name ReportWork
   * @summary 举报作品
   * @request POST:/v1/works/{id}/report
   */
  reportWork = (id: string, params: RequestParams = {}) =>
    this.request<ResponseResponseWrapper, any>({
      path: `/v1/works/${id}/report`,
      method: "POST",
      type: ContentType.Json,
      format: "json",
      ...params,
    })
}
