import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { isMobileOnly } from "react-device-detect"
import dayjs from "dayjs"

import MobileHeaderMenu from "@/components/layout/temp/MobileHeaderMenu"
import { V1Activity } from "@/generated/api/data-contracts"
import { ReactComponent as HomeMenu } from "@/assets/home/menu.svg"

import SortPopover from "@/common/SortPopover"
import menuStore from "@/store/menuStore"
import { useDarkMode } from "@/utils"
import Navbar from "@/Navbar"
import { observer } from "mobx-react-lite"
import activityStore from "@/store/activityStore"

import ActivityDescription from "./ActivityDescription"
import ActivityContribute from "./ActivityContribute"
import TemplateActivity from "./TemplateActivity"

import ImgActivity from "./ImgActivity"
import * as store from "@/store"
import CheckAuth from "@/components/common/CheckAuth"
import { getActivityReceive, getActivityTemplateList } from "@/services/home"
import { toastSuccess } from "@/common/Toast"
import { V1ActivityTemplateForUser } from "@/generated/api/data-contracts"
import _ from "lodash"
import TempMobile from "../Works/TempMobile"
import { getRecordNumberByDomain } from "@/constants"

type Tab = {
  type: string
  text: string
}

type TemplateCategoryItemMobileProps = {
  item: Tab
  activeTab: string
  onTabChange: (tab: string) => void
}

interface ActivityContentProps {
  activeTab: string
  isTmp: boolean
  contributeType: string
  id: string
  activityTemplateList?: V1ActivityTemplateForUser[]
}

const templateSort = [
  { label: "最新投稿", value: "newest" },
  { label: "最热投稿", value: "hotest" },
]

const tabs: Tab[] = [
  { type: "submission", text: "投稿作品" },
  { type: "activity", text: "活动介绍" },
  { type: "contribute", text: "立即投稿" },
]

const imgSort = [
  { label: "最新", value: "newest" },
  // { label: "最高分", value: "highest" },
  { label: "最受欢迎", value: "popular" },
]

const activeTabButtonClass =
  "px-4 py-1.5 text-sm rounded text-[#ffffff80] w-[93px] cursor-pointer text-white bg-[#161623] shadow"

const tabButtonClass =
  "px-4 py-1.5 text-sm rounded text-[#ffffff80] w-[93px] cursor-pointer aria-[current]:text-white aria-[current]:bg-[#161623] aria-[current]:shadow"

const ActivityContent = ({
  activeTab,
  isTmp,
  contributeType,
  id,
  activityTemplateList,
}: ActivityContentProps) => {
  if (activeTab === "submission") {
    if (isTmp) {
      return <TemplateActivity />
    } else {
      return <ImgActivity contributeType={contributeType} id={id} />
    }
  }

  if (activeTab === "contribute") {
    if (isMobileOnly) {
      return (
        <TempMobile
          tip="移动端活动投稿升级中"
          className="flex flex-col items-center justify-center gap-y-8 mb-10"
        />
      )
    }

    return <ActivityContribute activityTemplateList={activityTemplateList} id={id} />
  }

  return <ActivityDescription />
}

const activityScrollTo = () => {
  window.scrollTo({
    top: isMobileOnly ? 400 : 420,
    behavior: "smooth",
  })
}
const ActivityHeader: React.FC<{
  onTabChange: (tab: string) => void
  activityDetail: V1Activity & { isShow?: boolean }
  activityUserStatus: string
}> = ({ onTabChange, activityDetail, activityUserStatus }) => {
  const navigate = useNavigate()

  const activityImgRef = useRef<HTMLDivElement | null>(null)
  const [activityImgHeight, setActivityImgHeight] = useState<number>(0)

  const shortSplit = (text: string) => {
    if (isMobileOnly) return text
    let maxCharacters = 50
    let ellipsis = ""
    const textLength = text?.length

    if (activityImgHeight > 300) {
      maxCharacters = 50
    } else if (activityImgHeight > 230) {
      maxCharacters = 30
      if (textLength > maxCharacters) ellipsis = "..."
    } else {
      maxCharacters = 10
      if (textLength > maxCharacters) ellipsis = "..."
    }

    return text?.substring(0, maxCharacters) + ellipsis || ""
  }

  useEffect(() => {
    if (isMobileOnly) return
    const activityImgElement = activityImgRef.current
    if (!activityImgElement) return

    setActivityImgHeight(+activityImgElement?.clientWidth / 3.6)
  }, [])

  const {
    startAt: start,
    endAt: end,
    short = "",
    uid = "",
    bannerImages,
    state,
    isShow,
  } = activityDetail || {}
  const handleClick = () => {
    onTabChange("activity")
    activityScrollTo()
  }

  const handleClickToJump = () => {
    if (!isShow) {
      navigate(`/studio?activityID=${uid}`)
    } else {
      onTabChange("contribute")
      activityScrollTo()
    }
  }

  const handleActivityReceive = _.throttle(async () => {
    const res = await getActivityReceive({ activityUID: uid })

    if (res.success) {
      toastSuccess("领取奖励成功")
      store.refreshUser()
      activityStore.getActivityStatus(uid)
    }
  }, 1000)

  const flexStyles =
    "flex mt-auto items-center justify-center pt-[13px] pb-[13px] font-pingfang text-xs lg:text-base font-normal rounded-md cursor-pointer"

  const NotEngagedPcClass = `${flexStyles} w-full bg-[#4C1FFF] cursor-pointer`
  const NotReceivedPcClass = "flex mt-auto w-full gap-3"

  const NotEngagedMobileClass =
    "fixed sm:mt-16 sm:static sm:w-auto sm:left-0 sm:translate-x-0 left-1/2 transform w-[calc(100%-150px)] bottom-0 mb-12 -translate-x-1/2 whitespace-nowrap px-[55px] py-4 text-white bg-[#4c1fff] rounded disabled:brightness-50 hover:brightness-125 transition-[filter] text-sm font-sans font-normal leading-4 text-center z-10"
  const NotReceivedMobileClass =
    "fixed flex mt-auto bottom-0 w-full px-[20px] py-[10px] gap-3 bg-[#0C0C1A] h-auto z-10"

  const DefalutButtonClass = `${flexStyles} w-[50%] bg-[#4C1FFF] cursor-pointer`

  const defaultButton = (func: any, text: string, className: string) => {
    return (
      <CheckAuth needLogin={true} className={className} onClick={func}>
        {<button>{text}</button>}
      </CheckAuth>
    )
  }

  const disabledButtonClass = " !bg-[#313238] pointer-events-none cursor-not-allowed"

  const isActivityStart = state === "Ongoing"

  const NotEngagedButton = isActivityStart
    ? defaultButton(
        handleClickToJump,
        "立即投稿",
        isMobileOnly ? NotEngagedMobileClass : NotEngagedPcClass
      )
    : defaultButton(
        _,
        "活动未开始",
        isMobileOnly
          ? NotEngagedMobileClass + disabledButtonClass
          : NotEngagedPcClass + disabledButtonClass
      )

  const NotReceivedButton = (
    <div className={isMobileOnly ? NotReceivedMobileClass : NotReceivedPcClass}>
      {defaultButton(handleClickToJump, "继续投稿", DefalutButtonClass)}
      {defaultButton(handleActivityReceive, "领取奖励", DefalutButtonClass + " !bg-[#EB7219]")}
    </div>
  )

  const ReceivedButton = (
    <div className={isMobileOnly ? NotReceivedMobileClass : NotReceivedPcClass}>
      {defaultButton(handleClickToJump, "继续投稿", DefalutButtonClass)}
      {defaultButton(_, "奖励已领取", DefalutButtonClass + disabledButtonClass)}
    </div>
  )
  const obj = {
    NotEngaged: NotEngagedButton,
    NotReceived: NotReceivedButton,
    Received: ReceivedButton,
  }

  const startAt = start ? dayjs.unix(start).format("YYYY年MM月DD日HH时") : ""
  const endAt = end ? dayjs.unix(end).format("YYYY年MM月DD日HH时") : ""

  return (
    <div className="flex md:mt-12 mt-0 md:py-4 py-0 flex-col md:flex-row">
      <div className="md:w-[75%] w-full text-center text-2xl font-bold md:pr-[20px] pr-[0px]">
        <div
          ref={activityImgRef}
          className="w-full   rounded-[4px] object-fit group-hover:scale-[1.2] transition-transform transform-gpuansform duration-700"
          style={{
            height: isMobileOnly ? 235 : activityImgHeight,
            backgroundImage: `url(${bannerImages?.[isMobileOnly ? 1 : 0]})`,
            backgroundPosition: "center center",
            backgroundSize: "auto 100%",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>

      <div
        style={{ height: isMobileOnly ? 180 : activityImgHeight }}
        className="md:w-[25%] w-full rounded-md bg-[#09090e] px-[5px] py-[9px] lg:px-[30px] lg:py-[20px] flex flex-col items-start"
      >
        <div className="font-pingfang   text-xs lg:text-base  font-thin text-white">
          {shortSplit(short)}&nbsp;
          <span onClick={() => handleClick()} className="text-[#6663FF] cursor-pointer">
            查看更多
          </span>
        </div>
        <div className="font-open-sans text-xs lg:text-base lg:font-bold pt-5">
          活动时间：<span className="text-[#6663FF]">{startAt + "-" + endAt}</span>
        </div>

        {!isMobileOnly && obj[activityUserStatus as keyof typeof obj]}
      </div>
      {isMobileOnly && obj[activityUserStatus as keyof typeof obj]}
    </div>
  )
}

const SortBtn: React.FC<{
  activeTab: string
  onTabChange: (tab: string) => void
  cb: (value: string) => void
  isTmp: boolean
  tabs: Tab[]
}> = ({ activeTab, onTabChange, cb, isTmp, tabs }) => {
  return (
    <div className="flex justify-between my-[20px]">
      <div className="flex items-center rounded bg-[#0C0C1A] p-1">
        {tabs.map(({ type, text }) => (
          <div
            key={type}
            onClick={() => {
              onTabChange(type)
            }}
            className={activeTab === type ? activeTabButtonClass : tabButtonClass}
          >
            {text}
          </div>
        ))}
      </div>
      {activeTab === "submission" && (
        <SortPopover
          buttonClassName="pl-3 pb-2"
          sortTypeList={isTmp ? templateSort : imgSort}
          defaultSort="newest"
          onSelect={(value) => {
            // todo
            cb && cb(value)
          }}
        />
      )}
    </div>
  )
}

const SortBtnMobile: React.FC<{
  activeTab: string
  onTabChange: (tab: string) => void
  cb: (value: string) => void
  isTmp: boolean
}> = ({ activeTab, cb, isTmp }) => {
  return (
    <div className="flex ml-5 mt-2">
      {activeTab === "submission" && (
        <SortPopover
          buttonClassName="pl-1 pb-0"
          sortTypeList={isTmp ? templateSort : imgSort}
          defaultSort="newest"
          onSelect={(value) => {
            // todo
            cb && cb(value)
          }}
        />
      )}
    </div>
  )
}

const TemplateCategoryItemMobile: React.FC<TemplateCategoryItemMobileProps> = ({
  item,
  activeTab,
  onTabChange,
}) => {
  const isActive = activeTab === item.type

  return (
    <section
      onClick={() => {
        onTabChange(item.type)
      }}
      className={` md:pt-0 flex flex-col items-center justify-center min-w-max`}
    >
      <div
        className={` min-w-max text-base leading-7 ${
          isActive ? "text-[#ffffff]" : "text-[#ffffff]/50"
        }`}
      >
        {item.text}
      </div>
      <div className={` mt-1 w-8 h-[3px]  ${isActive ? "bg-[#4C1FFF]" : "transparent"}`}></div>
    </section>
  )
}

const ActivityMenu: React.FC<{
  isTop: boolean
  activeTab: string
  onTabChange: (tab: string) => void
}> = ({ isTop, activeTab, onTabChange }) => {
  return (
    <>
      <div
        className={`fixed z-10 w-full transition-opacity duration-500 ease-in-out ${
          isTop ? "opacity-100" : "opacity-0"
        }`}
      >
        <MobileHeaderMenu
          extra={
            <div className="flex gap-[20px]">
              {tabs.map((item) => {
                return (
                  <TemplateCategoryItemMobile
                    key={item.type}
                    item={item}
                    activeTab={activeTab}
                    onTabChange={onTabChange}
                  />
                )
              })}
            </div>
          }
        />
      </div>
      <div className="px-5 pt-5 absolute items-center md:hidden z-10">
        <HomeMenu
          className="w-5 h-5 mr-5"
          onClick={() => {
            menuStore.openMenu()
          }}
        />
      </div>
    </>
  )
}

const Activity: React.FC = () => {
  useDarkMode()

  const { activityDetail, activityUserStatus } = activityStore.store.get()
  const myElementRef = useRef<HTMLDivElement | null>(null)
  const [activeTab, setActiveTab] = useState<string>("submission")
  const [isTop, setIsTop] = useState<boolean>(false)
  const [contributeType, setContributeType] = useState<string>("newest")
  const [activityTabList, setActivityTabList] = useState<Tab[]>([])
  const [activityTemplateList, setActivityTemplateList] = useState<V1ActivityTemplateForUser[]>([])
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id") || ""
  const user = store.user.get()
  const isTmp = activityDetail.type === "template"

  useEffect(() => {
    fetchActivityTemplateList()
    if (user?.id) {
      activityStore.getActivityStatus(id)
    }
    activityStore.getActivityDetail(id)
  }, [])

  useEffect(() => {
    const myElement = myElementRef.current
    if (!myElement) return

    const handleScroll = () => {
      if (isElementAtTop(myElement)) {
        setIsTop(true)
      } else {
        setIsTop(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const isElementAtTop = (element: HTMLElement): boolean => {
    const elementRect = element.getBoundingClientRect()
    return elementRect.top <= 0
  }

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
    if (tab === "contribute") {
      activityScrollTo()
    }
  }

  const fetchActivityTemplateList = async () => {
    const res = await getActivityTemplateList({
      activityUID: id,
      page: 1,
      pageSize: 99999,
    })

    if (res.success) {
      const { data } = res
      setActivityTabList(data?.total ? tabs : [...tabs.slice(0, 2)])
      setActivityTemplateList(data?.activityTemplates)
    }
  }

  return (
    <section className="relative dark:text-[#fff] bg-[#161623]">
      <Navbar />
      {isMobileOnly ? (
        <>
          <ActivityMenu isTop={isTop} activeTab={activeTab} onTabChange={handleTabChange} />
          <ActivityHeader
            activityDetail={activityDetail}
            onTabChange={handleTabChange}
            activityUserStatus={activityUserStatus}
          />
          <div
            ref={myElementRef}
            className="flex gap-[20px] pl-5 pt-3 pb-2 border-b border-white border-opacity-10"
          >
            {activityTabList.map((item) => {
              return (
                <TemplateCategoryItemMobile
                  key={item.type}
                  item={item}
                  activeTab={activeTab}
                  onTabChange={handleTabChange}
                />
              )
            })}
          </div>
          <SortBtnMobile
            activeTab={activeTab}
            onTabChange={handleTabChange}
            cb={(v) => {
              setContributeType(v)
            }}
            isTmp={isTmp}
          />
          <div
            style={{ paddingBottom: activeTab === "submission" ? 0 : 0 }}
            className="px-[20px] pt-4"
          >
            <ActivityContent
              activeTab={activeTab}
              isTmp={isTmp}
              contributeType={contributeType}
              id={id}
            />
          </div>
        </>
      ) : (
        <div className="md:px-[50px] px-[10px] pt-[20px]  max-w-[1920px]">
          <ActivityHeader
            onTabChange={handleTabChange}
            activityDetail={activityDetail}
            activityUserStatus={activityUserStatus}
          />
          <SortBtn
            activeTab={activeTab}
            onTabChange={handleTabChange}
            cb={(v) => {
              setContributeType(v)
            }}
            isTmp={isTmp}
            tabs={activityTabList}
          />
          <ActivityContent
            activeTab={activeTab}
            isTmp={isTmp}
            contributeType={contributeType}
            id={id}
            activityTemplateList={activityTemplateList}
          />
        </div>
      )}

      <div className="flex-col md:flex-row mt-auto w-full flex justify-center text-xs py-3 items-center cursor-pointer">
        <a href="https://beian.miit.gov.cn/#/Integrated/index">{getRecordNumberByDomain()}</a>
        <a
          className="mt-1 md:mt-0 flex justify-center items-center"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
        >
          <img className="pl-2" src="/assets/beian.png" />
          <p className="pl-2">沪公网安备 31011502020083号</p>
        </a>
      </div>
    </section>
  )
}

export default observer(Activity)
