import { observable, action, computed, onBecomeObserved, onBecomeUnobserved } from "mobx"
import { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import JSZip from "jszip"
import { isMobileOnly } from "react-device-detect"
import { V1GetURLSchemeResponseCustom, getUrlScheme } from "@/services/home"
import { V5_MODELS, CONTROLNETMODELLIST } from "@/constants"

export const timestamp = observable.box(0)
let raf: number
onBecomeObserved(timestamp, () => {
  function loop() {
    const update = action((t: DOMHighResTimeStamp) => {
      timestamp.set(t)
      loop()
    })
    raf = requestAnimationFrame(update)
  }
  loop()
})
onBecomeUnobserved(timestamp, () => {
  cancelAnimationFrame(raf)
})

export const useComputed = <T>(get: () => T) => useMemo(() => computed(get), [])

export function useMobxSearchParams() {
  const [v] = useSearchParams()
  const [out, set] = useMemo(() => {
    const box = observable.box(v)
    return [box, action((v: URLSearchParams) => box.set(v))]
  }, [])
  set(v) // same value will not trigger re-render
  return out
}

export const handleDownload = async (url: string, isWatermark: boolean = true) => {
  const imageUrl = isWatermark ? getImageDownloadURL(url) : url
  const blob = await fetch(imageUrl).then((res) => res.blob())
  const blobUrl = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = blobUrl

  a.download = url.split("/")?.pop() || "download"
  a.click()
  URL.revokeObjectURL(blobUrl)
}

export const handleDownloadImgs = async (urls: string[]) => {
  const promiseList = urls.map((url) => ({
    name: url.split("/").pop() || url,
    p: fetch(getImageDownloadURL(url)).then((response) => response.blob()),
  }))
  Promise.all(promiseList).then((values) => {
    const zip = new JSZip()

    values.map((blob) => {
      zip.file(blob.name, blob.p)
    })
    zip.generateAsync({ type: "blob" }).then(function (content) {
      const link = document.createElement("a")
      link.download = `tiamat-download${new Date().getTime()}.zip`
      link.href = URL.createObjectURL(content)
      link.click()
    })
  })
}

/**
 * 价格展示逻辑： https://github.com/TiamatAI/xica-docs/issues/73#issuecomment-1478796902
 * 最长显示两位小数，并且小数部分末尾不能是 0
 */
export function formatPrice(price: number): string {
  let result = price.toFixed(2)
  if (result[result.length - 1] === "0") {
    result = result.slice(0, result.length - 1)
    if (result[result.length - 1] === "0") {
      result = result.slice(0, result.length - 2)
    }
  }
  return result
}

// 用作奖励时间转换，给出的默认是秒，直接返回算好的时间
export function formatTime(time: number): string {
  let str = ""
  if (time < 60) {
    str = time + " s"
  } else if (time < 60 * 60) {
    str = formatPrice(time / 60) + " min"
  } else if (time < 72 * 60 * 60) {
    str = formatPrice(time / (60 * 60)) + " h"
  } else if (time >= 72 * 60 * 60) {
    // 超过3天显示为天
    str = formatPrice(time / (60 * 60 * 24)) + " 天"
  }
  return str
}

/**
 * 解析尺寸字符串，支持 "width x height" 和 "width : height" 两种格式。
 */
function parseSize(size?: string): number[] {
  if (!size) return [0, 0]

  const sizeParts = size?.split(/[x:]/).map(Number)

  if (sizeParts?.length === 2) {
    return sizeParts
  } else {
    throw new Error('format error, size: "width x height" or "width : height"')
  }
}

/** 作品是否为竖图 */
export function isPortraitWork(size?: string): boolean {
  const [w, h] = parseSize(size) || [0, 0]
  return Number(w) < Number(h)
}

/** 作品宽高比 */
export function computeWorkRatio(size?: string): number {
  const [w, h] = parseSize(size) || [0, 0]
  return Number(w) / Number(h)
}

export function useDarkMode() {
  useEffect(() => {
    const bodyHasClassDark = document.documentElement.classList.contains("dark")
    const bodyHasAttrArcoTheme = document.body.getAttribute("arco-theme") === "dark"
    if (!bodyHasClassDark) {
      document.documentElement.classList.add("dark")
    }
    if (!bodyHasAttrArcoTheme) {
      // document.body.setAttribute("arco-theme", "dark") // TODO 原有组件样式需要兼容
    }
    // if (!bodyHasClassDark || !bodyHasAttrArcoTheme) {
    //   return () => {
    //     document.documentElement.classList.remove("dark")
    //     document.body.setAttribute("arco-theme", "")
    //   }
    // }
  }, [])
}

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return width
}

export function getColumnCount(windowWidth: number, isHome?: boolean) {
  // 1280每行5张图片, 1440每行6张图片, 1920每行7张图片, 小于450px一律展示2张， 其他情况自适配

  const customColumn = isHome ? [6, 5, 4, 2] : [7, 6, 5, 2]

  const columnCount =
    windowWidth >= 1920
      ? customColumn[0]
      : windowWidth >= 1440
      ? customColumn[1]
      : windowWidth >= 1280
      ? customColumn[2]
      : windowWidth <= 450
      ? customColumn[3]
      : void 0
  return columnCount
}

// getImageURL 获取图片预览链接, 主要是 jpg 格式 + 水印; 传入true则为80%的缩略图
export const getImageURL = (url = "", thumbnail = false) => {
  if (thumbnail) {
    return `${url}/xica_wj80`
  } else {
    return `${url}/xica_wj`
  }
}

// getImageJpg 将图片从png改为jpg格式，压缩大小
export const getImageJpg = (url = "") => {
  return `${url}/xica_j`
}

// getImageDownloadURL 获取图片下载链接, 与其他链接不同的是, 这个链接的图必须是 PNG 原图且带水印;
export const getImageDownloadURL = (url = "") => {
  return `${url}/watermark`
}

//  按照中英文逗号空格中英文分号进行拆分成标签
export const splitToTag = (str = "") => {
  let arr = []

  // try {
  //   arr = str.split(/(?<=\p{Script=Han})\s+(?=\p{Script=Han})|,|、|；|，/gmu)
  // } catch (error) {
  //   arr = str.split(/(?<![a-zA-Z])\s+(?![a-zA-Z])|,|、|；|，/gm)
  // }

  // return arr.filter((item) => item?.trim())

  if (str.includes("，")) {
    arr = str.split("，")
  } else if (str.includes("、")) {
    arr = str.split("、")
  } else if (str.includes(",")) {
    arr = str.split(",")
  } else if (str.includes("、")) {
    arr = str.split("、")
  } else if (str.includes("；")) {
    arr = str.split("；")
  } else if (str.includes("。")) {
    arr = str.split("。")
  } else if (str.trim().includes(" ")) {
    arr = str.split(" ")
  } else {
    arr = [str]
  }

  return arr
}

const setCookieForMobile = () => {
  const tokenValue =
    "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2OTU3ODU3MTQsImlhdCI6MTY5MzE5MzcxNCwiaXNzIjoiL3YxL3RpYW1hdC94aWNhIiwianRpIjoibG9tNEpCMzdHZ1A2SE5PTzRwTFcyIiwidXNlcl91aWQiOiJnbVA3eHNpMDFKM2xtcDhZdG16c20ifQ.-8ZVQjUokI_39JHIAHnZXsnPzjliLUiIqFpnmMTvHoK3YgEqHpjdb8O8-tYtKWlT-XpgtHP3o6rrzimCaieiWQ"
  if (isMobileOnly) {
    document.cookie = `xica_token=${tokenValue};`
    window.location.reload()
  }
}

export { setCookieForMobile }

export const formatSpiritStonesNum = (num: number) => {
  if (num === 0) {
    return ""
  } else if (num >= 10000) {
    const formattedNum = num / 10000 + ""
    return formattedNum.endsWith(".0") ? formattedNum.slice(0, -2) + "w" : formattedNum + "w"
  } else if (num >= 1000) {
    const formattedNum = num / 1000 + ""
    return formattedNum.endsWith(".0") ? formattedNum.slice(0, -2) + "k" : formattedNum + "k"
  } else {
    return num.toString()
  }
}

export const formatLoveNum = (num: number) => {
  if (num === 0) {
    return 0
  } else if (num >= 10000) {
    const formattedNum = (num / 10000).toFixed(2) + ""
    return formattedNum + "w"
  } else if (num >= 1000) {
    const formattedNum = (num / 1000).toFixed(2) + ""
    return formattedNum + "k"
  } else {
    return num.toString()
  }
}

export const featureDeprecatedAlert = () => {
  alert("该功能已下线")
}

export const JumpToWeChat = async () => {
  if (!isMobileOnly) {
    return
  }

  try {
    let urlParams = window.location.search

    if (!urlParams.includes("refer")) {
      const separator = urlParams.includes("?") ? "&" : "?"
      urlParams = urlParams + separator + "refer=web_scheme"
    }

    const [_, query] = urlParams.split("?")
    const params = {
      path: "/pages/index/index",
      params: query,
    }

    const res: V1GetURLSchemeResponseCustom = await getUrlScheme({ ...params })

    if (res?.success && res?.data?.openlink) {
      window.location.href = res.data.openlink
    }
  } catch (error) {
    console.error("请求失败：", error)
  }
}

export const findModelPattern = (
  model?: string,
  modelPattern?: {
    portrait: boolean
    art: boolean
  }
) => {
  let newModel = model

  const isTIMv5 = V5_MODELS.includes(model || "")
  if (isTIMv5) {
    const isAnime = (model || "").includes("auto-tianime") // 是否为动漫模型
    const modelId = isAnime
      ? modelPattern?.art && "auto-tianime-art"
      : modelPattern?.portrait && "auto-tiality-portrait"

    if (modelId) {
      newModel = modelId
    }
  }

  return newModel
}

export const filterControlNetModel = (modelVersion?: string, module?: string) => {
  const TIMv5ControlNetModel = ["canny", "depth", "openpose"]
  if (modelVersion === "TIMv5") {
    return CONTROLNETMODELLIST.filter((item) => {
      return TIMv5ControlNetModel.includes(item.value) && !module?.includes(item.value)
    })
  }

  return CONTROLNETMODELLIST
}

export const findConrtolNetModelLabel = (model?: string) => {
  const modelLabel = CONTROLNETMODELLIST.find((item) => item.value === model)?.label
  return modelLabel
}
