import { useEffect, useState } from "react"
import { useCountDown } from "ahooks"
import * as store from "@/store"
import {
  loginByPhoneWithInviteCode,
  registerByPhone,
  sendSmsCode,
  verifyInviteCode,
  verifyPhoneCode,
} from "@/services/login"
import { ReactComponent as LoginErrorIcon } from "@/assets/login_err.svg"
import { ReactComponent as BackIcon } from "@/assets/login_back.svg"
import { ReactComponent as CheckIcon } from "@/assets/check.svg"
import { ReactComponent as UserIcon } from "@/assets/user.svg"
import { ReactComponent as LockIcon } from "@/assets/lock_1.svg"
import { ReactComponent as StartIcon } from "@/assets/start.svg"
import { ReactComponent as MagicIcon } from "@/assets/magic.svg"
import { isPhone } from "@/utils/validate"
import { userData } from "@/store"
import { LogicError } from "@/types"
import { useTranslation } from "react-i18next"
import { isMobileOnly } from "react-device-detect"
import { ErrorCode } from "@/i18next"
import { Tooltip } from "@arco-design/web-react"
import { Link, useLocation, useNavigate, useOutlet, useParams } from "react-router-dom"
import { baiduTrack, bilicmReportData } from "@/utils/track"

interface PasswordProps {
  onClickToggle?: () => void
  //  移动端需要通过点击注册还是登录按钮来切换页面
  pageStatu?: "login" | "register" | ""
  // 立即注册/立即登录 切换按钮状态
  changeStatu?: (statu: "login" | "register" | "") => void
}
const StatuTextMap = new Map([
  ["login", "登录"],
  ["register", "注册"],
])
export const Password: React.FC<PasswordProps> = ({ onClickToggle, pageStatu, changeStatu }) => {
  const location = useLocation()
  const [isLogin, setIslogin] = useState(true)
  useEffect(() => {
    // "register"时islogin为 false  默认和"login"为登录
    //  路由参数是游客一次机会结束后跳转到注册 pageStatu 是移动端的注册登录按钮切换
    const ParamStatu = new URLSearchParams(location.search)
    const status = ParamStatu.get("status")
    let loginState = pageStatu === "register" || status === "register"
    setIslogin(loginState)
  }, [pageStatu])

  const outlet = useOutlet()

  return (
    <>
      <div className="flex flex-col justify-around items-center relative flex-1 md:max-w-[400px] w-[100%] bg-white md:rounded-lg px-[40px] md:px-0 rounded-t-[40px]">
        {outlet || (
          <div className="md:pt-[69px] pt-8 md:pb-[6px] pb-8">
            <LoginRegisterPart
              isLogin={isLogin}
              pageStatu={pageStatu}
              changeStatu={changeStatu}
              onIsLoginChange={() => {
                setIslogin(!isLogin)
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}

interface LoginRegisterPartProps {
  isLogin: boolean
  pageStatu?: "login" | "register" | ""
  changeStatu?: (statu: "login" | "register" | "") => void
  onIsLoginChange?: (isLogin: boolean) => void
}
export const LoginRegisterPart = ({
  isLogin,
  pageStatu,
  changeStatu,
  onIsLoginChange,
}: LoginRegisterPartProps) => {
  return (
    <>
      {!isLogin ? (
        <>
          <LoginComponent pageStatu={pageStatu} />
          <div className="my-2">
            <span className="text-black">没有账号？</span>
            <span
              onClick={() => {
                onIsLoginChange?.(!isLogin)
                // 移动端使用此组件时才会有changeStatu这个回调函数
                pageStatu === "login" && changeStatu ? changeStatu("register") : ""
              }}
              className=" cursor-pointer text-[#5C16F1] hover:text-[#734AFF]"
            >
              立即注册
            </span>
          </div>
        </>
      ) : (
        <>
          <LogonComponent pageStatu={pageStatu} />
          <div className="my-2">
            已有账号？
            <span
              onClick={() => {
                onIsLoginChange?.(!isLogin)
                pageStatu === "register" && changeStatu ? changeStatu("login") : ""
              }}
              className=" cursor-pointer text-[#5C16F1] hover:text-[#734AFF]"
            >
              立即登录
            </span>
          </div>
        </>
      )}
    </>
  )
}

// 登录
const LoginComponent: React.FC<PasswordProps> = ({ pageStatu }) => {
  const { t } = useTranslation(["translation", "errorMessage"])
  // const navigate = useNavigate()
  return (
    <>
      <div className="flex justify-center text-[#101010] text-[26px] md:mb-[28px] mb-3">
        {isMobileOnly ? StatuTextMap.get(pageStatu || "") : t("SignIn")}
      </div>
      {/*  移动端不展示 */}
      {!isMobileOnly && <div className="text-[#101010] text-[16px]">短信验证登录</div>}
      <PhoneAndCodeLogin
        type="login"
        nextStep={() => {
          location.replace("/")

          // navigate("/", { replace: true })
        }}
      />
    </>
  )
}
// 注册
const LogonComponent: React.FC<PasswordProps> = ({ pageStatu }) => {
  const { t } = useTranslation(["translation", "errorMessage"])
  const [invite, setInvite] = useState("")
  // const [showInviteForm, setShowInviteForm] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      {/* {!showInviteForm && (
      <div
        onClick={() => setShowInviteForm(true)}
        className="absolute flex justify-center items-center md:left-[14px] left-2 md:top-[14px] top-8 h-[44px] w-[44px] hover:cursor-pointer hover:bg-[rgb(201,203,209)]/30 rounded-[4px]"
      >
        <BackIcon />
      </div>
       )} */}
      <div className="flex justify-center text-[#101010] text-[26px] md:mb-[28px] mb-3">
        {isMobileOnly ? StatuTextMap.get(pageStatu || "") : t("LogonTitle")}
      </div>
      {!isMobileOnly && <div className="text-[#101010] text-[16px]">账号注册</div>}

      <>
        {/* <div className="text-[#101010] text-[16px]">
          {!showInviteForm ? "激活码绑定" : "邀请码确认"}
        </div>
        {showInviteForm ? (
          <EnterInviteComponent
            type="logon"
            nextStep={(invite) => {
              setInvite(invite || "")

              setShowInviteForm(false)
            }}
          />
        ) : ( */}
        <PhoneAndCodeLogin
          type="logon"
          invite={invite}
          nextStep={() => {
            // TODO 强制刷新获取心的用户信息，store更新无法通知到没有用observer包裹的组件
            // location.replace("/")
            navigate("/login/profile")
            // navigate("/", { replace: true })
          }}
        />
        {/* )} */}
      </>
    </>
  )
}

const PhoneAndCodeLogin = ({
  nextStep,
  type,
  invite,
}: {
  nextStep?: () => void
  type: "login" | "logon"
  invite?: string
}) => {
  const [showTip, setShowTip] = useState(false)
  const [agree, setAgree] = useState(false)
  const { t } = useTranslation(["translation", "errorMessage"])
  // 手机号
  const [phone, setPhone] = useState("")
  const [phoneErr, setPhoneErr] = useState("")
  // 验证码
  const [code, setCode] = useState("")
  const [codeErr, setCodeErr] = useState("")

  const [loading] = useState(false)
  const [targetDate, setTargetDate] = useState<undefined | number>()

  const [count] = useCountDown({
    targetDate,
    onEnd: () => console.log("count down end"),
  })

  const handleClickSendCode = async () => {
    try {
      if (!validatePhone()) throw new Error("手机号填写错误")
      setTargetDate(Date.now() + 60 * 1000)
      const code = await sendSmsCode(phone)
      setCode(code || "")
    } catch (error) {
      // TODO: 错误处理
    }
  }

  const validatePhone = (value = phone) => {
    if (value === "" || !isPhone(value)) {
      setPhoneErr("请确认手机号格式")
      return false
    } else {
      setPhoneErr("")
      return true
    }
  }

  const validateCode = (value = code, pvalue = phone) => {
    if (!/^\d{6}$/.test(value) && !(pvalue.startsWith("19") && value.length >= 16)) {
      setCodeErr("请再次确认验证码")
      return false
    } else {
      setCodeErr("")
      return true
    }
  }

  const submit = () => {
    if (!validatePhone() || !validateCode()) {
      return
    }
    if (!agree) {
      setShowTip(true)
      return
    }
    type === "login" ? login() : logon()
  }

  const login = async () => {
    try {
      const user = await verifyPhoneCode({ phone, verificationCode: code })
      userData.set({
        ...userData.get(),
        ...user,
        status: "ok",
      })
      nextStep?.()
    } catch (e) {
      if (e instanceof LogicError) {
        switch (e.code) {
          case "11001":
            setPhoneErr(t("errorMessage:11001"))
            break
          case "11002":
            setCodeErr(t("errorMessage:11002"))
            break
          case "11004":
            setCodeErr(t("errorMessage:11004"))
            break
          default:
            setCodeErr(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
        }
      }
    }
  }
  const logon = async () => {
    try {
      const user = await registerByPhone({
        // inviteCode: invite || "",
        sourceV2: store.fromQuery.get(),
        phone: phone,
        verificationCode: code,
      })
      userData.set({
        ...userData.get(),
        ...user,
        status: "ok",
      })

      // Report data to bilibili
      bilicmReportData()

      // Report success status of submit to Baidu
      baiduTrack.submitSuccess()

      nextStep?.()
    } catch (e) {
      if (e instanceof LogicError) {
        switch (e.code) {
          case "11001":
            setPhoneErr(t("errorMessage:11001"))
            break
          case "11008":
            setPhoneErr(t("errorMessage:11008"))
            break
          case "11002":
            setCodeErr(t("errorMessage:11002"))
            break
          case "11004":
            setCodeErr(t("errorMessage:11004"))
            break
          default:
            setCodeErr(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
        }
      }
    }

    // if (res) {
    //   console.log(res)
    // }
    //  TODO 注册接口
  }

  // Click outside to close the tooltip
  useEffect(() => {
    const handler = () => {
      if (event?.target) {
        const target = event.target as HTMLElement
        const checkIconCls = "checkicon_container"
        const startBtnCls = "start_button_container"
        if (
          target.classList.contains(checkIconCls) ||
          target.closest(`.${checkIconCls}`) ||
          target.classList.contains(startBtnCls) ||
          target.closest(`.${startBtnCls}`)
        ) {
          return
        }
      }
      setShowTip(false)
    }
    document.addEventListener("click", handler)
    return () => {
      document.removeEventListener("click", handler)
    }
  }, [])

  return (
    <>
      {/* 手机号输入 */}
      <div className="w-full flex flex-col justify-center items-center">
        {isMobileOnly && <div className="w-full">账号</div>}
        <label
          className={`h-11 md:w-80 w-full md:mt-4 md:px-3 flex items-center md:rounded border-b-[1px] md:border ${
            phoneErr ? "border-[red]" : ""
          } focus-within:border-[#724AFF]`}
        >
          {/*  移动端不展示图标 */}
          {!isMobileOnly && <UserIcon />}
          <input
            name="phone"
            className="md:ml-2 flex-1 md:py-2 py-[13.5px] text-sm text-black outline-none bg-transparent placeholder:text-[#C9CBD1] border-none"
            placeholder="请输入手机号码"
            value={phone}
            onChange={(e) => {
              phoneErr && validatePhone(e.target.value)
              setPhone(e.target.value)
            }}
          ></input>
          {phoneErr ? <LoginErrorIcon /> : null}
        </label>
        {/* {phoneErr ? ( */}
        <div className="self-start ml-10 text-3 mt-[6px] md:mb-[3px] md:h-[20px] text-sm text-[#E93247]">
          {phoneErr}
        </div>
        {/* ) : null} */}
      </div>

      {/* 验证码输入 */}
      <div className="w-full flex flex-col justify-center items-center">
        {isMobileOnly && <div className="w-full"> 验证码</div>}
        <div className="w-full md:w-auto flex justify-between">
          <label
            className={`${
              codeErr ? "border-[red]" : ""
            } h-11 md:w-80  md:px-3 flex items-center md:rounded  border-b-[1px] md:border focus-within:border-[#724AFF]`}
          >
            {/* 移动端不展示图标 */}
            {!isMobileOnly && <LockIcon className=" shrink-0" />}
            <input
              className="md:ml-2 flex-1 min-w-0 py-2 text-sm text-black outline-none bg-transparent placeholder:text-[#C9CBD1]"
              placeholder="请输入验证码"
              value={code}
              onChange={(e) => {
                codeErr && validateCode(e.target.value)
                setCode(e.target.value)
              }}
            />
            {codeErr ? <LoginErrorIcon className=" shrink-0" /> : null}
            {/* PC端发送验证码 */}
            {!isMobileOnly && (
              <>
                <div className="w-0 h-4 border-[1px] border-solid border-[rgba(0, 0, 0, 0.2)] m-[14px]"></div>
                {count === 0 ? (
                  <div
                    className="text-[14px] w-[86px] text-[#5A14F0] cursor-pointer text-center"
                    onClick={handleClickSendCode}
                  >
                    获取验证码
                  </div>
                ) : (
                  <div className="text-[14px] w-[86px] text-[#CBC7DC] flex items-center">
                    <div className="flex justify-end w-[26px]">{Math.round(count / 1000)}s</div>
                    &nbsp;重新获取
                  </div>
                )}
              </>
            )}
          </label>
          {/* 移动端发送验证码按钮 */}
          {isMobileOnly &&
            (count === 0 ? (
              <div
                className="text-[14px]  md:text-[#5A14F0] text-white cursor-pointer py-[6px] px-[30px] bg-[#6663FF] flex flex-col justify-center rounded-[4px]"
                onClick={handleClickSendCode}
              >
                <div className="w-[30px] text-center">获取</div>
              </div>
            ) : (
              <div className="text-[14px] text-[#CBC7DC] py-[6px] px-[30px] bg-[#6663FF] flex flex-col justify-center rounded-[4px]">
                <div className="w-[30px] flex justify-center">{Math.round(count / 1000)}s</div>
              </div>
            ))}
        </div>
        <div className="self-start ml-10 text-3 mt-[6px] mb-[3px] h-[20px] text-sm text-[#E93247]">
          {codeErr}
        </div>
      </div>
      {/* 协议和按钮 */}
      <div className="w-full flex flex-col justify-center items-center">
        <p className="text-center text-[#CBC7DC] text-[13px] md:mb-1 ">
          <Tooltip position="tl" content="请先勾选同意后再进行登录" popupVisible={showTip}>
            <span
              onClick={() => {
                const agreeVal = !agree
                setAgree(agreeVal)
                setShowTip(!agreeVal)
              }}
              className={`checkicon_container inline-flex w-4 h-4 rounded-xl cursor-pointer ${
                agree ? "bg-[#5A14F0]" : ""
              } border border-[#5A14F0] shadow-sm justify-center items-center mr-2`}
            >
              <CheckIcon className="" />
            </span>
          </Tooltip>
          <span>
            阅读并同意
            <a
              target="_blank"
              className="text-[#8952FF]"
              href="https://cos-xica-prod.tiamat.world/assets/Tiamat%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf"
              rel="noreferrer"
            >
              《Tiamat 用户协议》
            </a>
            和
            <a
              target="_blank"
              className="text-[#8952FF]"
              href="https://cos-xica-prod.tiamat.world/assets/Tiamat%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96-20240123-V1.pdf"
              rel="noreferrer"
            >
              《隐私条款》
            </a>
          </span>
        </p>
        <button
          className="start_button_container text-white md:mt-[4px] mt-[21px] mb-4 block md:w-80 w-full px-8 py-2.5 text-base bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded disabled:opacity-50 active:brightness-110"
          disabled={loading}
          onClick={submit}
        >
          <div className="flex justify-center items-center">
            {loading ? "登录中" : "开始创作"}
            <MagicIcon className="ml-1 h-6" />
          </div>
        </button>
      </div>
    </>
  )
}

const EnterInviteComponent = ({
  nextStep,
  type,
}: {
  nextStep?: (invite?: string) => void
  type: "login" | "logon"
}) => {
  const { t } = useTranslation(["translation", "errorMessage"])

  // 邀请码
  const [invite, setInvite] = useState("")
  const [inviteErr, setInviteErr] = useState("")
  const [loading, setLoading] = useState(false)
  loading
  const verifyInvite = (value: string = invite) => {
    if (value === "") {
      setInviteErr("请再次确认邀请码")
      return false
    } else {
      setInviteErr("")
      return true
    }
  }
  const handleChangeInvite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    verifyInvite(value)
    setInvite(value)
  }

  const submit = () => {
    if (!verifyInvite()) {
      return
    }
    type === "login" ? handleLoginWithEnterInvite() : handleLogonWithEnterInvite()
  }

  const handleLoginWithEnterInvite = async () => {
    try {
      setLoading(true)
      // TODO: 适应一下mobx数据格式
      const user: any = await loginByPhoneWithInviteCode(invite)
      userData.set({
        ...userData.get(),
        ...user,
        status: "ok",
      })
    } catch (e) {
      if (e instanceof LogicError) {
        switch (e.code) {
          case "11002":
            setInviteErr(t("errorMessage:11002"))
            break
          default:
            setInviteErr(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
        }
      }
    } finally {
      setLoading(false)
    }
  }
  const handleLogonWithEnterInvite = async () => {
    try {
      await verifyInviteCode({
        inviteCode: invite,
      })
      console.log("--", nextStep, "nextStep")

      nextStep?.(invite)
    } catch (e) {
      if (e instanceof LogicError) {
        switch (e.code) {
          case "11002":
            setInviteErr(t("errorMessage:11002"))
            break
          default:
            setInviteErr(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
        }
      }
    }
  }

  return (
    <>
      <label className="h-11 md:w-80 w-full mt-4 px-3 flex items-center rounded border  focus-within:border-[#724AFF]">
        <StartIcon />
        <input
          className="ml-2 flex-1 py-2 text-sm text-black outline-none bg-transparent placeholder:text-[#C9CBD1]"
          placeholder="请输入邀请码"
          value={invite}
          onChange={(e) => {
            inviteErr && verifyInvite(e.target.value)
            handleChangeInvite(e)
          }}
        ></input>
      </label>
      <div className="self-start ml-10 text-3 mt-[6px] mb-[3px] h-[20px] text-sm text-[#E93247]">
        {inviteErr}
      </div>
      <button
        disabled={!!inviteErr}
        className="text-white mt-[3px] mb-4 block md:w-80 w-full px-8 py-2.5 text-base bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded disabled:opacity-50 active:brightness-110"
        onClick={submit}
      >
        立即激活
      </button>
      <Link to={"/login/apply"} replace>
        <button className="text-[#5C16F1] mb-2 block md:w-80 w-full px-8 py-2.5 text-base bg-gradient-to-r border-[#734AFF] border rounded hover:opacity-80 active:brightness-110">
          获取邀请码
        </button>
      </Link>
    </>
  )
}
