import React, { useState, useEffect, useRef } from "react"
import { useDarkMode } from "@/utils"
import {
  V1ActivityTemplateForUser,
  V1Work,
  V1CreateTaskWithActivityTemplateRequest,
} from "@/generated/api/data-contracts"
import { ReactComponent as SelectedIcon } from "@/assets/activity_selected.svg"
import { ReactComponent as IconUploadLoading } from "@/assets/loading-dash.svg"
import modelSeletIcon from "@/assets/studio/model_select.svg"
import sizeIcon from "@/assets/studio/size.svg"
import ImageSizeUI from "../../Studio/components/StudioOptions/ImageSize/ImageSizeUI"
import ModalSelectUI from "../../Studio/components/StudioOptions/ModalSelect/ModalSelectUI"
import NormalInput from "../../Studio/components/PromptBox/components/NormalInput"
import { WarnningModal } from "../../Studio/components/WarningModal"
import { ActivityUploadImage } from "./components/ActivityUploadImage"
import { getActivityTemplateTask } from "@/services/home"
import { toastInfo, toastError } from "@/common/Toast"
import { getTaskDetail } from "@/services/editor"
import { Progress } from "@/Studio/RuningTask"
import CheckAuth from "@/components/common/CheckAuth"
import activityStore from "@/store/activityStore"
import { IllegalWork } from "@/common/IllegalWork"
import { useTranslation } from "react-i18next"
import { LogicError } from "@/types"
import { ErrorCode } from "@/i18next"
import { getImageURL } from "@/utils"

interface ActivityContributeProps {
  activityTemplateList?: V1ActivityTemplateForUser[]
  id?: string
}

interface Task {
  id?: string
}

interface Work {
  id: string
  title: string
  works: V1Work[]
}

const ActivityContribute: React.FC<ActivityContributeProps> = (props) => {
  useDarkMode()

  const { t } = useTranslation(["translation", "errorMessage", "randomPrompt"])

  const ActivityUploadImageRef = useRef<any>(null)
  const { activityTemplateList = [], id } = props || {}

  // 默认选中第一个
  const [activeActivityTemplate, setActiveActivityTemplate] =
    useState<V1ActivityTemplateForUser | null>(null)

  const [upLoadImg, setUpLoadImg] = useState<string>("")
  const [modelId, setModelId] = useState<string>("DongManFengGe")
  const [ratio, setRatio] = useState<string>("1:1")
  const [descriptionValue, setDescriptionValue] = useState<string>("")
  const [task, setTask] = useState<Task>({})
  const [status, setStatus] = useState<string>("")
  const [works, setWorks] = useState<Work[]>([])
  const [createdAt, setCreatedAt] = useState<string>("")
  const [generateButtonDisabled, setGenerateButtonDisabled] = useState<boolean>(false)
  const [showWarningModal, setShowWarningModal] = useState(false)

  useEffect(() => {
    if (!task?.id) return
    const intervalId = setInterval(() => {
      // 如果任务已经成功或失败，则停止轮询
      if (status === "success" || status === "failed") {
        clearInterval(intervalId)
        setGenerateButtonDisabled(false)
        setCreatedAt("")
        setStatus("")
        setTask({})
        return
      }

      const fetchData = async () => {
        try {
          const res = await getTaskDetail(task.id || "", { format: "json" })
          const taskData = res?.data?.data || {}
          if (taskData) {
            const updatedStatus = taskData.status
            const updatedWorks = taskData.works || []
            const updateCreatedAt = taskData.createdAt

            setStatus(updatedStatus)

            if (updatedStatus === "success" || updatedStatus === "failed") {
              let newUpdateWorks = [...updatedWorks]

              const paddingWorksCount = 4 - (newUpdateWorks?.length ?? 0)
              if (paddingWorksCount > 0) {
                for (let i = 0; i < paddingWorksCount; i++) {
                  if (!newUpdateWorks) {
                    newUpdateWorks = [
                      {
                        ratio: taskData.ratio,
                        illegal: true,
                      },
                    ]
                  } else {
                    newUpdateWorks?.push({
                      ratio: taskData.ratio,
                      illegal: true,
                    })
                  }
                }
              }
              const obj: Work = {
                title: activeActivityTemplate?.title || "",
                id: activeActivityTemplate?.activityTemplateUID || "",
                works: newUpdateWorks,
              }
              setWorks([obj, ...works])
              activityStore.getActivityStatus(id || "")
            }

            setCreatedAt(updateCreatedAt)
          }
        } catch (e) {
          toastError(e instanceof Error ? e.message : "unknown error")
        }
      }

      fetchData()
    }, 2000)

    return () => {
      clearInterval(intervalId)
    }
  }, [status, task?.id])

  useEffect(() => {
    const defalutActivityTemplate = activityTemplateList?.[0] || null
    setActiveActivityTemplate(defalutActivityTemplate)
  }, [activityTemplateList])

  const activityTemplateDom = () => {
    return activityTemplateList?.map((item) => {
      const { activityTemplateUID, title, officialImageURLs = [] } = item || {}
      const isActive = activeActivityTemplate?.activityTemplateUID === activityTemplateUID

      const ItemBorder = ({ active }: { active?: boolean }) => {
        return (
          <div
            className={`absolute top-0 left-0 w-full h-full border-[3px] rounded-md border-solid ${
              active ? "border-[#4C1FFF]" : "border-transparent"
            }`}
          ></div>
        )
      }

      return (
        <div
          onClick={() => setActiveActivityTemplate(item)}
          key={activityTemplateUID}
          className="col-span-1 rounded-md flex h-40  max-w-[12.5rem] flex-col justify-end items-center relative cursor-pointer"
        >
          <div
            className="w-full rounded-tl-md rounded-md"
            style={{
              height: 160,
              backgroundImage: `url(${officialImageURLs[0]})`,
              backgroundPosition: "center center",
              backgroundSize: "auto 100%",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="absolute top-0 w-full h-full flex justify-center items-end">
            <div
              className={`flex flex-col w-full items-center rounded-bl-md rounded-br-md justify-end bg-${
                isActive ? "[#4C1FFF]" : "[#000000]"
              } py-1.5`}
            >
              <div className="relative w-fit mt-[-1.00px] [font-family:'Open_Sans-Regular',Helvetica] font-normal text-white text-[16px] text-center tracking-[0] leading-[normal]">
                {title}
              </div>
            </div>
          </div>
          {isActive ? (
            <>
              <div className="absolute top-[1px] right-[2px]  z-50 rounded-md">
                <SelectedIcon />
              </div>
              <div
                style={{
                  clipPath: "polygon(6% 97%, 40% 0%, 72% 95%)",
                }}
                className="clip-path-trapezoid absolute  top-[3px] right-[-23px] bg-[#4C1FFF] transform rotate-45 py-2.5 px-7"
              ></div>
            </>
          ) : (
            ""
          )}
          <ItemBorder active={isActive} />
        </div>
      )
    })
  }

  const upLoadReference = () => {
    const handleChange = (value: any) => {
      setUpLoadImg(value?.inputImageURL)
    }
    return (
      <div className="relative w-auto max-w-[260px] max-h-[260px]">
        <CheckAuth needLogin={true}>
          <ActivityUploadImage
            ratio={ratio}
            handleChange={handleChange}
            ref={ActivityUploadImageRef}
            init={{
              inputImageURL: upLoadImg || undefined,
            }}
          />
        </CheckAuth>
      </div>
    )
  }

  const selectModel = () => {
    return (
      <>
        <div className="flex items-center flex-1 pb-[10px]  -ml-2">
          <img src={modelSeletIcon} className="w-7 h-7 object-cover" />
          <div className="ml-2 text-white text-base font-normal leading-tight">模型选择</div>
        </div>
        <ModalSelectUI
          isActivity={true}
          modelId={modelId}
          onClick={setModelId}
          className="max-h-none bg-none p-0 rounded  md:bg-[#161623]  md:max-h-[380px] overflow-y-auto scrollbar-hidden"
        />
      </>
    )
  }

  const selectRatio = () => {
    return (
      <>
        <div className="flex items-center flex-1 pb-[10px] -ml-2">
          <img src={sizeIcon} className="w-7 h-7 object-cover" />
          <div className="ml-2 text-white text-base font-normal leading-tight">图片尺寸</div>
        </div>
        <ImageSizeUI
          className="flex justify-center 2xl:gap-[10px] xl:gap-[5px] gap-[2px]"
          onClick={setRatio}
          selectedRatio={ratio}
        />
      </>
    )
  }

  const validate = () => {
    const { enablePrompt, enableCustom } = activeActivityTemplate || {}

    if (enableCustom && !upLoadImg) {
      toastInfo("请上传参考图")
      return false
    }

    if (enablePrompt && !descriptionValue) {
      toastInfo("请输入描述词")
      return false
    }

    return true
  }

  const handleGenerateImage = async () => {
    const { enableCustom, enableRatio, enableAbstractModel, enablePrompt } =
      activeActivityTemplate || {}
    const validateCheck = validate()
    if (!validateCheck) return

    let referencedImageURL = ""
    if (enableCustom) {
      const fetchUpLoadImage = ActivityUploadImageRef?.current?.uploadCropImage
      setGenerateButtonDisabled(true)
      referencedImageURL = await fetchUpLoadImage()

      if (referencedImageURL === "error") {
        setGenerateButtonDisabled(false)
        return
      }

      // 如果出现错误，fetchUpLoadImage 内部会return
    } else {
      setGenerateButtonDisabled(true)
    }

    const params: V1CreateTaskWithActivityTemplateRequest = {
      activityTemplateUID: activeActivityTemplate?.activityTemplateUID || "",
      activityUID: id || "",
      textPrompt: descriptionValue,
      model: modelId,
      ratio,
      referencedImageURL: referencedImageURL || undefined,
    }

    if (!enableRatio) {
      delete params?.ratio
    }

    if (!enableAbstractModel) {
      delete params?.model
    }

    if (!enablePrompt) {
      delete params?.textPrompt
    }

    if (!enableCustom) {
      delete params?.referencedImageURL
    }

    try {
      const res = await getActivityTemplateTask(params)

      if (res?.success) {
        setTask({ id: res?.data?.id })
      }
    } catch (e) {
      setGenerateButtonDisabled(false)
      if (e instanceof LogicError) {
        if (e.code == "12008") {
          setShowWarningModal(true)
        } else {
          toastError(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
        }
      } else {
        toastError(e instanceof Error ? e.message : "unknown error")
      }
    }
  }

  const GenerateButton = () => {
    return (
      <CheckAuth needLogin={true}>
        <button
          disabled={generateButtonDisabled}
          onClick={() => {
            handleGenerateImage?.()
          }}
          className="flex w-[187px] h-12 flex-col justify-center items-center gap-[30px] rounded px-10 py-0 bg-[#4C1FFF] cursor-pointer text-[color:var(--ffffff,var(--100,#FFF))] text-center text-lg not-italic font-normal leading-5 disabled:cursor-not-allowed disabled:brightness-50"
        >
          <div className="flex items-center justify-center">
            {generateButtonDisabled && <IconUploadLoading className="animate-spin mr-2 w-5 h-5" />}
            <span>生成</span>
          </div>
        </button>
      </CheckAuth>
    )
  }

  const DescriptionInput = () => {
    return (
      <>
        <div className="h-48 mb-5 max-w-[900px]">
          <NormalInput
            value={descriptionValue}
            onChange={(text) => setDescriptionValue(text)}
            placeholder="请输入图片描述"
          />
        </div>
        <GenerateButton />
      </>
    )
  }

  const { enablePrompt, enableCustom, enableAbstractModel, enableRatio } =
    activeActivityTemplate || {}

  const stepFn = () => {
    if (!enableCustom && !enableAbstractModel && !enableRatio) {
      return 2
    }

    if (!enableCustom && (enableAbstractModel || enableRatio)) {
      return 3
    }

    if (enableCustom && !enableAbstractModel && !enableRatio) {
      return 3
    }

    return 4
  }
  const stepText = {
    2: "第二步",
    3: "第三步",
    4: "第四步",
  }

  const getAdjustedHeight = (ratio: string) => {
    type RatioObjType = {
      [key: string]: number
    }

    const ratioObj: RatioObjType = {
      "1:1": 420,
      "3:4": 560,
      "4:3": 315,
      "16:9": 236,
      "9:16": 746,
    }

    const windowWidth = window.innerWidth // 窗口宽度
    const adjustedHeight = (windowWidth / 1920) * 420
    return (adjustedHeight * ratioObj[ratio]) / 420
  }

  return (
    <div className="flex flex-col relative w-full">
      <div className="relative w-full ">
        <div className="flex flex-col w-full items-start  py-[20px]">
          {/* <div className="relative w-fit mt-[-1.00px] [font-family:'Open_Sans-Light',Helvetica] font-light text-white text-[48px] text-center tracking-[0] leading-[normal]">
            Step 1
          </div> */}
          <div className="w-fit [font-family:'Open_Sans-SemiBold',Helvetica] font-semibold text-white text-[20px] text-center leading-[normal] relative tracking-[0]">
            第一步：选择标签
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4  2xl:grid-cols-8  lg:grid-cols-6  gap-6">
          {activityTemplateDom()}
        </div>
      </div>
      <div className="relative self-stretch w-full h-[40px]" />
      <div className="grid xl:grid-cols-4 gap-4 grid-cols-2 w-full ">
        {enableCustom && (
          <div className="col-span-1 ">
            {/* <div className="relative w-fit mt-[-1.00px] [font-family:'Open_Sans-Light',Helvetica] font-light text-white text-[48px] text-center tracking-[0] leading-[normal] ">
              Step 2
            </div> */}
            <div className="w-fit pb-10 [font-family:'Open_Sans-SemiBold',Helvetica] font-semibold text-white text-[20px] text-center leading-[normal] relative tracking-[0]">
              第二步：上传参考图
            </div>

            {upLoadReference()}
            {!enableAbstractModel && !enableRatio && !enablePrompt && (
              <div className="mt-10">
                <GenerateButton />
              </div>
            )}
          </div>
        )}
        {(enableAbstractModel || enableRatio) && (
          <div className="col-span-1">
            {/* <div className="relative w-fit mt-[-1.00px] [font-family:'Open_Sans-Light',Helvetica] font-light text-white text-[48px] text-center tracking-[0] leading-[normal]">
              {!enableCustom ? "Step 2" : " Step 3"}
            </div> */}
            <div className="w-fit pb-10 [font-family:'Open_Sans-SemiBold',Helvetica] font-semibold text-white text-[20px] text-center leading-[normal] relative tracking-[0]">
              {!enableCustom ? "第二步：" : "第三步："}
              {enableAbstractModel && enableRatio
                ? `选择模型及比例`
                : `${enableAbstractModel ? "选择模型" : "选择比例"} `}
            </div>
            <div className="max-w-[336px]">
              {enableAbstractModel && selectModel()}
              <div className="relative self-stretch w-full h-[40px]" />
              {enableRatio && selectRatio()}
            </div>
            {!enablePrompt && (
              <div className="mt-10">
                <GenerateButton />
              </div>
            )}
          </div>
        )}
        {enablePrompt && (
          <div className="col-span-2">
            {/* <div className="relative w-fit mt-[-1.00px] [font-family:'Open_Sans-Light',Helvetica] font-light text-white text-[48px] text-center tracking-[0] leading-[normal]">
              Step {stepFn()}
            </div> */}
            <div className="w-fit  pb-10  [font-family:'Open_Sans-SemiBold',Helvetica] font-semibold text-white text-[20px] text-center leading-[normal] relative tracking-[0]">
              {stepText[stepFn()] + "：输入描述词"}
            </div>
            {DescriptionInput()}
          </div>
        )}
      </div>
      <div className="relative self-stretch w-full h-[80px]" />
      <div className="flex flex-col items-center justify-center">
        {(status === "running" || status === "pending") && (
          <Progress createdAt={createdAt ?? ""}></Progress>
        )}
      </div>

      {works?.length ? (
        <div>
          <div className="w-fit pb-10 [font-family:'Open_Sans-SemiBold',Helvetica] font-semibold text-white text-[20px] text-center leading-[normal] relative tracking-[0]">
            完成: 生成结果
          </div>
          {works?.map((item) => {
            return (
              <div key={item?.id} className="pb-10">
                <div className="inline-flex flex-col justify-end items-center gap-1.5 px-3 py-1.5 bg-[#2E2E4A] mb-4">
                  {item?.title}
                </div>
                <div className="grid grid-cols-4 w-full gap-11">
                  {item?.works?.map((work) => {
                    const { image, ratio = "1:1" } = work || {}

                    return (
                      <>
                        <div className="col-span-1 w-full">
                          {work?.illegal ? (
                            <IllegalWork
                              className="w-full h-full"
                              style={{ height: getAdjustedHeight(ratio || "1:1") }}
                              taskId={task?.id}
                            />
                          ) : (
                            <div
                              className="w-full rounded"
                              style={{
                                height: getAdjustedHeight(ratio || "1:1"),
                                backgroundImage: `url(${getImageURL(image)})`,
                                backgroundPosition: "center center",
                                backgroundSize: "auto 100%",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                          )}
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        ""
      )}
      <div className="relative self-stretch w-full h-[80px]" />
      {showWarningModal ? <WarnningModal onClose={() => setShowWarningModal(false)} /> : null}
    </div>
  )
}

export default ActivityContribute
