import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import Studio from "../Studio"
import Subscription from "@/pages/Subscription"
import UserCenter from "@/pages/UserCenter"
import { HomeV2 } from "@/pages/HomeV2/HomeV2"
import Activity from "@/pages/Activity"
import { TemplateCenter } from "@/pages/TemplateCenter"
import LayoutGeneral from "@/components/layout/general"
import UserGuide from "@/pages/UserGuide"
import Login from "@/pages/Login"
import LoginApplay from "@/pages/Login/Apply"
import Visitor from "@/pages/Visitor"
import * as store from "../store"
import WorkLauyout from "@/pages/Works/WorkLauyout"
import WorkCollect from "../pages/Works/Collect"
import WorkGenerated from "../pages/Works/Generated"
import DajiaPreview from "@/pages/DajiaInsurance/mobile"
import DajiaLanding from "@/pages/DajiaInsurance/mobile-landing"
import Template from "@/pages/Works/Template"
import LoginQC from "../pages/Login/loginQC"
import { MOBILE_CONTAINER_CLS } from "../store/menuStore"
import LoginProfile from "../components/bussiness/ProfileEditor/LoginProfile"

import WorksPage from "../pages/Works"
import {
  PATH_WORKS,
  PATH_WORKS_MY_ALBUMS,
  PATH_WORKS_MY_TEMPLATES,
  PATH_WORKS_MY_WORKS,
} from "./constants"
import Albumn from "@/pages/Works/Album"
import WorksTab from "@/pages/Works/WorksTab"
import AlbumDetail from "@/pages/Works/AlbumDetail"

interface AuthGuardProps {
  Cmp: React.ComponentType<any>
  [key: string]: unknown
}
// 登录和申请账号页面不需要这个组件
function AuthGuard({ Cmp, ...props }: AuthGuardProps) {
  const user = store.user.get()
  return user.id ? <Cmp {...props} /> : <Navigate to="/login" />
}

type RouterConfigItemType = {
  path: string
  element?: JSX.Element
  children?: RouterConfigItemType[]
}

const routers: RouterConfigItemType[] = [
  {
    path: "/login",
    element: <Login />,
    children: [
      {
        path: "profile",
        element: <LoginProfile />,
      },
    ],
  },
  {
    path: "/loginQC",
    element: <LoginQC />,
  },
  {
    path: "/login/apply",
    element: <LoginApplay />,
  },
  {
    path: "/user-center",
    element: <AuthGuard Cmp={UserCenter} />,
  },
  {
    path: "/user-guide",
    element: <UserGuide />,
  },
  {
    path: "/subscription",
    element: <Subscription />,
  },
  {
    path: "/dajia-mobie-preview",
    element: <DajiaPreview />,
  },
  {
    path: "/dajia-landing",
    element: <DajiaLanding />,
  },
  {
    path: "/visitor/:userId",
    element: <AuthGuard Cmp={Visitor} />,
  },
  {
    path: "/workx/",
    element: <AuthGuard Cmp={WorkLauyout} fav={false} key="recent" />,
    children: [
      {
        path: "",
        element: <WorkGenerated />,
      },
      {
        path: "collect",
        element: <WorkCollect />,
      },
    ],
  },
  {
    path: PATH_WORKS,
    element: <AuthGuard Cmp={WorksPage} />,
    children: [
      {
        path: "",
        element: <Navigate to={`/${PATH_WORKS}/${PATH_WORKS_MY_WORKS}`} replace />,
      },
      {
        path: PATH_WORKS_MY_WORKS,
        element: <WorksTab />,
      },
      {
        path: PATH_WORKS_MY_ALBUMS,
        element: <Albumn />,
      },
      {
        path: PATH_WORKS_MY_TEMPLATES,
        element: <Template />,
      },
    ],
  },
  {
    path: "/users/:userId",
    children: [
      {
        path: "",
        element: <Navigate to={`./${PATH_WORKS}`} replace />,
      },
      {
        path: PATH_WORKS,
        element: <AuthGuard Cmp={WorksPage} />,
        children: [
          {
            path: "",
            element: <Navigate to={`./${PATH_WORKS_MY_WORKS}`} replace />,
          },
          {
            path: PATH_WORKS_MY_WORKS,
            element: <WorksTab />,
          },
          {
            path: PATH_WORKS_MY_ALBUMS,
            element: <Albumn />,
          },
          {
            path: PATH_WORKS_MY_TEMPLATES,
            element: <Template />,
          },
        ],
      },
      {
        path: `${PATH_WORKS_MY_ALBUMS}/:albumId`,
        element: <AuthGuard Cmp={AlbumDetail} />,
      },
    ],
  },
  {
    path: `${PATH_WORKS_MY_ALBUMS}/:albumId`,
    element: <AuthGuard Cmp={AlbumDetail} />,
  },
  {
    path: "/studio",
    element: (
      <LayoutGeneral hasHeader={true} className={MOBILE_CONTAINER_CLS}>
        <Studio />
      </LayoutGeneral>
    ),
  },
  {
    path: "/activity",
    element: (
      <LayoutGeneral>
        <Activity />
      </LayoutGeneral>
    ),
  },
  {
    path: "/templateCenter",
    element: <TemplateCenter />,
  },
  {
    path: "/*",
    element: (
      <LayoutGeneral>
        <HomeV2 />
      </LayoutGeneral>
    ),
  },
]

const renderRouters = (routers?: RouterConfigItemType[]) => {
  if (!routers) {
    return null
  }
  return (
    <>
      {routers.map((router) => (
        <Route key={router.path} path={router.path} element={router.element}>
          {renderRouters(router.children)}
        </Route>
      ))}
    </>
  )
}

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {renderRouters(routers)}
        {/* {routers.map((router) => (
          <Route key={router.path} path={router.path} element={router.element} />
        ))} */}
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
