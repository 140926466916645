import { useState } from "react"
import { useCountDown } from "ahooks"
import * as store from "@/store"
import {
  loginByPhoneWithInviteCode,
  registerByPhone,
  loginByPhoneQc,
  sendSmsCode,
  verifyInviteCode,
  verifyPhoneCode,
} from "@/services/login"
import { ReactComponent as LoginErrorIcon } from "@/assets/login_err.svg"
import { ReactComponent as BackIcon } from "@/assets/login_back.svg"
import { ReactComponent as CheckIcon } from "@/assets/check.svg"
import { isPhone } from "@/utils/validate"
import { userData } from "@/store"
import { LogicError } from "@/types"
import { useTranslation } from "react-i18next"
import { isMobileOnly } from "react-device-detect"
import { ErrorCode } from "@/i18next"
import { Tooltip } from "@arco-design/web-react"
import { Link } from "react-router-dom"
import { useSearchParams } from "react-router-dom"

export const PasswordQC = ({ handleLoginSuccess }: { handleLoginSuccess: () => void }) => {
  const [isLoginSuccess, setIsloginSuccess] = useState(false)
  return (
    <div className="flex flex-col items-center relative flex-1 max-w-[400px] md:pt-[69px] pt-8 md:pb-[6px] pb-8 bg-white rounded-3xl px-4 md:px-0 ">
      {isLoginSuccess ? (
        <div>
          <div className="flex text-center items-center justify-center text-[#101010] text-2xl md:mb-[28px] my-16">
            登录成功
            <br />
            <br />
            请关闭网页，返回QQ频道
          </div>
          <button
            className="text-white mt-[4px] mb-4 block md:w-80 w-full px-8 py-2.5 text-base bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded disabled:opacity-50 active:brightness-110"
            onClick={() => {
              var userAgent = navigator.userAgent
              if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
                window.location.href = "about:blank"
                window.close()
              } else {
                window.opener = null
                window.open("", "_self")
                window.close()
              }
            }}
          >
            <div className="flex justify-center items-center">关闭网页，前往qq频道</div>
          </button>
        </div>
      ) : (
        <>
          <div className="flex justify-center text-[#101010] text-[26px] md:mb-[28px] mb-3">
            登录/注册
          </div>
          <div className="text-[#101010] text-[16px] text-center text-xs">
            为维护社区秩序，净化网络环境。 <br />
            请登录以继续享受更多精彩内容。
            <br />
            输入您的手机号码并完成验证码验证，即可访问官方频道。 <br />
            感谢您的理解和支持！
          </div>
          <div className="h-4"></div>
          <PhoneAndCodeLogin
            nextStep={() => {
              setIsloginSuccess(true)
              handleLoginSuccess()
              //   location.replace("/")
            }}
          />
        </>
      )}
    </div>
  )
}

const PhoneAndCodeLogin = ({ nextStep }: { nextStep?: () => void }) => {
  const [search, setSearch] = useSearchParams()
  const [showTip, setShowTip] = useState(false)
  const [agree, setAgree] = useState(false)
  const { t } = useTranslation(["translation", "errorMessage"])
  // 手机号
  const [phone, setPhone] = useState("")
  const [phoneErr, setPhoneErr] = useState("")
  // 验证码
  const [code, setCode] = useState("")
  const [codeErr, setCodeErr] = useState("")

  const [loading] = useState(false)
  const [targetDate, setTargetDate] = useState<undefined | number>()

  const [count] = useCountDown({
    targetDate,
    onEnd: () => console.log("count down end"),
  })

  const handleClickSendCode = async () => {
    try {
      if (!validatePhone()) throw new Error("手机号填写错误")
      setTargetDate(Date.now() + 60 * 1000)
      const code = await sendSmsCode(phone)
      setCode(code || "")
    } catch (error) {
      // TODO: 错误处理
    }
  }

  const validatePhone = (value = phone) => {
    if (value === "" || !isPhone(value)) {
      setPhoneErr("请确认手机号格式")
      return false
    } else {
      setPhoneErr("")
      return true
    }
  }

  const validateCode = (value = code, pvalue = phone) => {
    if (!/^\d{6}$/.test(value) && !(pvalue.startsWith("19") && value.length >= 16)) {
      setCodeErr("请再次确认验证码")
      return false
    } else {
      setCodeErr("")
      return true
    }
  }

  const submit = () => {
    if (!validatePhone() || !validateCode()) {
      return
    }
    if (!agree) {
      setShowTip(true)
      return
    }
    logon() // 注册
  }

  const logon = async () => {
    try {
      const user = await loginByPhoneQc({
        channelID: search.get("channelID"),
        channelUserID: search.get("channelUserID"),
        // sourceV2: store.fromQuery.get(),
        phone: phone,
        verificationCode: code,
      })
      userData.set({
        ...userData.get(),
        ...user,
        status: "ok",
      })
      nextStep?.()
    } catch (e) {
      if (e instanceof LogicError) {
        switch (e.code) {
          case "11001":
            setPhoneErr(t("errorMessage:11001"))
            break
          case "11008":
            setPhoneErr(t("errorMessage:11008"))
            break
          case "11002":
            setCodeErr(t("errorMessage:11002"))
            break
          case "11004":
            setCodeErr(t("errorMessage:11004"))
            break
          default:
            setCodeErr(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
        }
      }
    }
  }
  return (
    <>
      <div className="pl-2 w-full text-left">账号</div>
      <label
        className={`h-11 md:w-80 w-full px-3 flex items-center rounded  ${
          phoneErr ? "border-[red]" : ""
        } focus-within:border-[#724AFF]`}
      >
        <input
          className="flex-1 py-2 text-sm text-black outline-none bg-transparent placeholder:text-[#C9CBD1]"
          placeholder="请输入手机号码"
          style={{ borderBottom: "1px solid #724AFF" }}
          value={phone}
          onChange={(e) => {
            phoneErr && validatePhone(e.target.value)
            setPhone(e.target.value)
          }}
        ></input>
        {phoneErr ? <LoginErrorIcon /> : null}
      </label>
      <div className="self-start ml-2 text-3 mt-[6px] mb-[3px] h-[20px] text-sm text-[#E93247]">
        {phoneErr}
      </div>
      <div className="pl-2 w-full text-left">验证码</div>
      <label
        className={`${
          codeErr ? "border-[red]" : ""
        } h-11 md:w-80 w-full px-3 flex items-center rounded focus-within:border-[#724AFF]`}
      >
        <input
          className=" flex-1 py-2 text-sm text-black outline-none bg-transparent placeholder:text-[#C9CBD1]"
          placeholder="请输入验证码"
          style={{ borderBottom: "1px solid #724AFF" }}
          value={code}
          onChange={(e) => {
            codeErr && validateCode(e.target.value)
            setCode(e.target.value)
          }}
        />
        {codeErr ? <LoginErrorIcon className=" shrink-0" /> : null}
        <div className="w-0 h-4 border-[1px] border-solid border-[rgba(0, 0, 0, 0.2)] m-[14px]"></div>
        {count === 0 ? (
          <div
            className="text-[14px] bg-[#5A14F0] text-white px-3 rounded py-1 cursor-pointer"
            onClick={handleClickSendCode}
          >
            发送验证码
          </div>
        ) : (
          <div
            className="text-[14px] bg-white  text-[#5A14F0] px-2 rounded py-1 cursor-pointer"
            style={{ border: "1px solid #5A14F0" }}
          >
            {Math.round(count / 1000)}s 重新获取
          </div>
        )}
      </label>
      <div className="self-start ml-2 text-3 mt-[6px] mb-[3px] h-[20px] text-sm text-[#E93247]">
        {codeErr}
      </div>
      <p className="text-center text-[#CBC7DC] text-[13px] mb-1">
        <Tooltip
          position="tl"
          content="请先勾选同意后再进行登录"
          popupVisible={showTip}
          trigger="click"
          onVisibleChange={(val) => {
            setShowTip(val)
          }}
        >
          <span
            onClick={() => {
              setAgree(!agree)
            }}
            className={`inline-flex w-4 h-4 rounded-xl cursor-pointer ${
              agree ? "bg-[#5A14F0]" : ""
            } border border-[#5A14F0] shadow-sm justify-center items-center mr-2`}
          >
            <CheckIcon className="" />
          </span>
        </Tooltip>
        <span>
          阅读并同意
          <a
            target="_blank"
            className="text-[#8952FF]"
            href="https://cos-xica-prod.tiamat.world/assets/Tiamat%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf"
            rel="noreferrer"
          >
            《Tiamat 用户协议》
          </a>
          和
          <a
            target="_blank"
            className="text-[#8952FF]"
            href="https://cos-xica-prod.tiamat.world/assets/Tiamat%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96-20240123-V1.pdf"
            rel="noreferrer"
          >
            《隐私条款》
          </a>
        </span>
      </p>
      <button
        className="text-white mt-[4px] mb-4 block md:w-80 w-full px-8 py-2.5 text-base bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded disabled:opacity-50 active:brightness-110"
        disabled={loading}
        onClick={submit}
      >
        <div className="flex justify-center items-center">{loading ? "登录中" : "开始创作"}</div>
      </button>
    </>
  )
}
